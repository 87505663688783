import React, { useContext, useEffect } from "react";
import { CpSelectSingle } from "canopy-styleguide!sofe";
import { featureEnabled } from "feature-toggles!sofe";
import { signatureElements } from "../../../constants";
import { SignerPill } from "../signer-pill.component";
import PanelDragWrapper from "./panel-drag-wrapper.component";
import { SigningContext } from "../../../signing-context";
import { getContactType } from "../../../signing-modal.helper";

export const FieldsTab = ({ isTemplateMode }) => {
  const { getSelectedSigners, isNewCrm, selectedSigner, setSelectedSigner } = useContext(SigningContext);
  const selectedSigners = getSelectedSigners();
  const isTeamMember = selectedSigner?.user_role === "TeamMember" || selectedSigner?.role === "TeamMember";

  useEffect(() => {
    // if selectedSigner not set then initialize with selectedSigners[0]
    if (!selectedSigner && selectedSigners.length > 0) {
      setSelectedSigner(selectedSigners[0]);
    }
  }, [selectedSigners]);

  return (
    <div className="min-w-0">
      <CpSelectSingle
        triggerIsBlock
        contentWidth={308}
        data={selectedSigners}
        onChange={setSelectedSigner}
        transformData={(signer) => ({
          ...signer,
          name: `${signer?.isSelf ? "(You) " : ""}${signer.name}`,
          icon: "shape-circle-closed",
          ...(isNewCrm
            ? {
                overline: getContactType(signer),
              }
            : {}),
          iconColor:
            signer?.user_role === "TeamMember" || signer?.role === "TeamMember"
              ? "var(--cp-color-pill-team-text)"
              : "var(--cp-color-default-badge-bg)",
          isTeamMember: signer?.user_role === "TeamMember" || signer?.role === "TeamMember",
        })}
        value={selectedSigner}
      />
      <div className="cp-flex-center flex-wrap cp-mv-16">
        {selectedSigner &&
          signatureElements
            .filter((element) => !(element.type === "text" && !featureEnabled("toggle_files_esign_improvements")))
            .map((element) => (
              <React.Fragment key={element.type}>
                <PanelDragWrapper
                  signatureElement={element}
                  signer={selectedSigner}
                  isTeamMember={isTeamMember}
                  isTemplateMode={isTemplateMode}
                >
                  <SignerPill
                    isTemplateMode={isTemplateMode}
                    text={element.text}
                    dragHandle
                    icon={element.cpIcon}
                    teamMember={isTeamMember}
                    dragSource
                  />
                </PanelDragWrapper>
              </React.Fragment>
            ))}
      </div>
    </div>
  );
};
