import React from "react";
import styles from "../kba.styles.css";
import { CpIcon, CpTooltip } from "canopy-styleguide!sofe";

export const InfoTooltip = ({ icon, fill }) => (
  <CpTooltip
    position="right-start"
    text={
      <div id="kba_desc">
        <h4 className={styles.kbaHeader}>Signer Authentication</h4>
        Enabling recipient ID verification will require tax-payer recipients to authenticate their identity through
        knowledge-based authentication before signing a document.
        <p className="cp-wt-bold" style={{ marginBottom: "7px" }}>
          Required by IRS for eSigning forms:
        </p>
        <p className="wells" style={{ margin: "0" }}>
          <span className={styles.kbaChip}>8878</span>
          <span className={styles.kbaChip}>8879</span>
        </p>
        <p className={styles.contactNow}>
          You can purchase credits in Account Management or by calling a product specialist at{" "}
          <a href="tel:18556167305" className={styles.phone}>
            1-855-616-7305
          </a>
        </p>
      </div>
    }
  >
    <CpIcon name={icon} fill={fill}></CpIcon>
  </CpTooltip>
);
