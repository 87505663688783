import React from "react";
import PropTypes from "prop-types";
import ReactDOMClient, { createRoot } from "react-dom/client";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { findIndex, noop, isNil, uniqBy, cloneDeep, isNull } from "lodash";
import { handleError } from "src/handle-error.helper.js";
import singleSpaReact from "single-spa-react";
import { ErrorBoundary } from "error-logging!sofe";
import { Subject, from, forkJoin } from "rxjs";
import { v4 as uuid } from "uuid";
import { hasAccess, UserTenantProps } from "cp-client-auth!sofe";

import { featureEnabled } from "feature-toggles!sofe";
import {
  signatureElements,
  signingFieldTypes,
  userRoles,
  featureToggles,
  clientTypes,
  templateTypes,
  verticalThreshold,
} from "../constants.js";
import {
  isClient,
  remapSigningLocationsFromRatio,
  remapAllSigningLocationsFromRatio,
  remapSigningLocationsToRatio,
  userMatchesSigningLocation,
} from "../signing-modal.helper.js";
import {
  createClientRequest,
  sendClientRequest,
  getClientRequest,
  updateClientRequest,
} from "../client-request/client-request.resource.js";
import {
  createSigningDocs,
  createSigningLocations,
  getSigningDocPages,
  getSigningLocations,
  signAllUserLocations,
  getClientCollaborators,
  getFileEsignSvgs,
  postEsignatureForDoc,
  getSigners,
  postUser,
  getSignerTypes,
} from "../signing.resource.js";
import { getSignedKba } from "../kba/kba.resource";
import { createEsignSignatureInfo, translateXfromPDF, translateYfromPDF } from "../signing-resource.helper";
import toasts from "toast-service!sofe";
import Disposable from "react-disposable-decorator";
import { EsignInline } from "./esign-inline.component";
import { EsignModal } from "./esign-modal.component";
import { EsignConfirmCloseModal } from "./esign-confirm-close-modal.component";
import { EsignContext, SigningContext } from "../signing-context";

let scrollTimeout = null;

@Disposable
@UserTenantProps()
export class EsignContainer extends React.Component {
  static propTypes = {
    clientId: PropTypes.number,
    closeModal: PropTypes.func.isRequired,
    context: PropTypes.shape({
      loggedInUser: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
    title: PropTypes.string.isRequired,
    documentId: PropTypes.string.isRequired,
    documentType: PropTypes.oneOf(["letter", "file"]),
    document: PropTypes.object,
    signingId: PropTypes.string,
    resolutionCaseId: PropTypes.string,
    notificationIds: PropTypes.array,
    clientRequestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    esignDocument: PropTypes.shape({
      svg_urls: PropTypes.array,
      consented_to_electronic_records: PropTypes.bool,
      signing_locations: PropTypes.array,
      completed: PropTypes.bool,
      kba_enabled: PropTypes.bool,
      kba_attempts_remaining: PropTypes.number,
      kba_time_limit: PropTypes.number,
      kba_attempts_allowed: PropTypes.number,
    }),
    onVerifyKba: PropTypes.func,
    inlineMode: PropTypes.bool,
    taxPrepManualSign: PropTypes.bool,
    taskId: PropTypes.string,
  };

  constructor(props) {
    super(props);

    props.esignDocument && props.esignDocument.completed && this.getSignedKbas(props.esignDocument.id);
    let signingSVGs = null;

    if (props.esignDocument && !props.esignDocument.completed) {
      if (featureEnabled("esign_pdf")) {
        signingSVGs = props.esignDocument.pdf_urls.map((url) => ({ url }));
      } else {
        signingSVGs = props.esignDocument?.svg_urls?.map((url) => ({ url }));
      }
    }

    const defaultDescription =
      "Please review and sign the letter below. It will be automatically sent to me when you've finished signing. Thanks!";

    this.state = {
      buttonScroll: false,
      clearPrev: false,
      clientCollaborators: {},
      clientHeight: 0,
      clientRequestData: null,
      consented: props.esignDocument ? props.esignDocument.consented_to_electronic_records : false,
      creditsSubtracted: 0,
      documentCompleted: props.esignDocument ? props.esignDocument.completed : false,
      documentSizes: [],
      esignError: false,
      esignTaskId: null,
      isClient: isClient(props.context),
      kbaEnabled: false,
      loggedInUserSignature: {
        id: props.context.loggedInUser.id,
        user_id: props.context.loggedInUser?.user_id,
        signatureText: null,
        initials: null,
        font: null,
        completed_at: null,
      },
      loadedPages: 0,
      markFieldAsSigned: () => {},
      nextLocation: null,
      prevLocation: null,
      primaryClientClientPortalUsers: [],
      scrollEnded: true,
      scrollPosition: 0,
      selectSignerNewUser: [],
      setEsignContext: this.setEsignContext,
      setSignersContext: this.setSignersContext,
      showClientRequestCreatedSuccessfullyModal: false,
      showEsignRequestCreatedSuccessfullyModal: false,
      showSignatureEntryModal: false,
      signersContext: [],
      signingId: props.signingId,
      signingLocations: props.esignDocument
        ? props.esignDocument.signing_locations
        : props.esignTemplate
        ? props.esignTemplate?.signing_locations?.map((location) => ({
            id: uuid(),
            ...location,
          }))
        : [],
      isTemplate: false,
      hasSelf: false,
      showSignerDropdown: {},
      signingLocationsAreDraggable: !props.clientRequestId && !isClient(props.context),
      signingPagesLoaded: false,
      signingRequestIsValid: false,
      practitionerMustSign: false,
      signingSVGs,
      signedSVGs: null,
      esignContext: this.props.esignTemplate
        ? this.props.esignTemplate
        : {
            title: `eSign request: ${this.props.title}`,
            due_at: null,
            reminders: {
              reminder_interval: null,
              expiration_interval_days: null,
            },
            description: defaultDescription,
          },
      esignSent: false,
      signerTypesContext: [],
      setSignerTypesContext: this.setSignerTypesContext,
      scrollTo: {},
      setScrollTo: this.setScrollTo,
      activeSignerFieldId: null,
      setActiveSignerFieldId: (id) => this.setState({ activeSignerFieldId: id }),
      selectedSigner: null,
      setSelectedSigner: this.setSelectedSigner,
      selectedSignerIds: [],
      setSelectedSignerIds: this.setSelectedSignerIds,
      getSelectedSigners: this.getSelectedSigners,
      showEsignConfirmCloseModal: false,
      notValidErrorMessage: "",
      allSignerTypes: [],
      isNewCrm: featureEnabled("ft_crm") && this.props.tenant.crm_status === "crm_hierarchy_complete",
      // getSigningExperience (for letters) returns the data with a url property, so here we are matching the data structure so urls can be handled the same
    };

    this.setDocumentSizes = (pageNum, documentSize) => {
      this.setState(({ documentSizes, signingLocations }) => {
        documentSizes[pageNum] = documentSize;
        const remappedSigningLocations = remapSigningLocationsFromRatio(signingLocations, pageNum, documentSize);
        return { documentSizes, signingLocations: remappedSigningLocations };
      });
    };

    this.uniqueUserSigningMap = {};
  }

  createSignersFromPrimaryEmail = (
    { first_name, last_name, name, emails },
    users,
    signingUsers,
    clientRelationship
  ) => {
    if (this.props.isTemplateMode) return;
    // if there are no alternate emails return
    if (!emails) return;
    // determine if the client already has a client portal Client user
    const hasClientUsers = users.find((user) => user.role === "Client");
    this.setState({ primaryClientClientPortalUsers: users });

    // if no cp Client users
    if (!hasClientUsers) {
      // get the primary email address from the emails list
      const primaryEmail = emails?.find((email) => email.isPrimary);

      // determine there are also no existing signers with that email already
      const isSigner = signingUsers.find(
        (user) => user.email === primaryEmail.value.toLowerCase() && user.name === name
      );
      if (isSigner) return;

      // create a signer with the primary email and client details
      const newSigner = {
        first_name: first_name,
        last_name: last_name,
        name: name,
        email: primaryEmail.value,
        client_id: Number(this.props.clientId),
      };

      postUser(newSigner).subscribe(
        (newUser) =>
          this.setState((prevState) => {
            return {
              signersContext: [
                ...prevState.signersContext,
                { ...newUser, role: userRoles.client, canEdit: true, clientType: clientRelationship },
              ],
            };
          }),
        handleError
      );
    }
  };

  componentDidMount() {
    const esignTemplateAccess = hasAccess(this.props?.context?.loggedInUser)("templates_esign_apply");
    this.setState({ esignTemplateAccess });
    // first get the esignTemplatesFeatureBubble
    // but not in client mode we will use straight toggle there
    //practitioner mode must be NOT client (not cp) and NOT preauth (not preauth link)
    if (!this.state.isClient && !this.props.preAuthSigner) {
      if (!this.state.isClient && esignTemplateAccess) {
        this.props.cancelWhenUnmounted(
          getSignerTypes().subscribe(({ signer_types }) => {
            this.setState({ allSignerTypes: signer_types });
            if (this.props.isTemplateMode) {
              if (this.props.esignTemplate.template_type === templateTypes.preset.id) {
                this.setSignerTypesContext(signer_types.filter((type) => type.preset));
              } else {
                this.setSignerTypesContext(signer_types);
              }

              const mapSigningLocations = this.state.signingLocations?.map((location) => {
                const signerType = signer_types.find((type) => type.id === location.signer_type_id);
                return {
                  ...location,
                  role: location?.role || signerType?.user_role,
                  ...signatureElements.find((el) => el.type === location.type),
                };
              });
              this.setSigningLocations(mapSigningLocations);
              this.setSelectedSignerIds(
                uniqBy(this.props.esignTemplate.signing_locations, "signer_type_id").map((loc) => loc.signer_type_id)
              );
              this.setKbaEnabled(this.props.esignTemplate.kba_enabled);
            }
          })
        );
      }
    }

    // determine and prep a few things up front
    // first, is this a continuation of a signing experience? (we have a signingId)
    if (!this.state.documentCompleted) this.createOrGetSigningExperience();
    this.signingRequestIsValid();

    if (this.props.isTemplateMode) return;
    if (!this.state.isClient) {
      this.setClientCollaborators(this.props.clientId);
    }

    toSigningObservable.subscribe(({ action }) => {
      switch (action) {
        case "DONE_SIGNING":
          return this.completeDocumentAsClient();
      }
    });
    if (!this.props.preAuthSigner && !this.state.isClient && !this.state.isNewCrm) {
      // we fetch the client portal users and the signers associated with the client (spouse, dependents, etc)
      this.oldCrmSub = forkJoin({
        signersResponse: getSigners(this.props.clientId),
        clientsResponse: getClientCollaborators(this.props.clientId),
      }).subscribe((response) => {
        const {
          signersResponse,
          clientsResponse: {
            clients: {
              emails,
              first_name,
              is_business,
              last_name,
              name,
              users,
              relationships: { clients, client_for },
            },
          },
        } = response;

        // if users does not include the loggedInUser then they are not
        // assigned to client, add them to users so they show in the dropdown
        const updatedUsers = users.find((user) => user.id === this.props.context.loggedInUser.id)
          ? users
          : [...users, this.props.context.loggedInUser];

        // with the signers we add role and canEdit to each signer's object
        // we remove any signers that are already client portal users (no duplicates)
        const signingUsers = signersResponse
          .map((signer) => {
            let relatedClients = [...clients, ...client_for];
            // if dependent, spouse or the current client we want it shown under "Clients" and not "Other Clients"
            const type =
              relatedClients.find(
                (client) =>
                  (client?.relationship_type === "spouse" || client?.relationship_type === "dependent") &&
                  client.emails?.find((email) => email.value === signer.email)
              ) ||
              (name === signer.name && emails?.find((email) => email.value === signer.email))
                ? clientTypes.client
                : clientTypes.other;

            return {
              ...signer,
              role: userRoles.client,
              canEdit: true,
              clientType: type,
            };
          })
          .filter((signer) => !updatedUsers.find((user) => user.id === signer.user_id && user.name === signer.name));

        // we're sorting the client portal users alphabetically
        const alphabetizedClientPortalUsers = updatedUsers
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((user) => {
            const userType = user.role === userRoles.client ? clientTypes.client : clientTypes.teamMember;
            const isSelf = user.id === this.props.context.loggedInUser.id;
            return { ...user, clientType: userType, isSelf };
          });

        this.setState({ signersContext: alphabetizedClientPortalUsers });

        // if the client is not a business
        // and if the it's not a client request (an already sent esign)
        // and if the user is not a CLIENT PORTAL user
        const isCpUser = emails?.find((email) =>
          alphabetizedClientPortalUsers.find((user) => user.email.toLowerCase() === email.value.toLowerCase())
        );
        if (!is_business && !this.props.clientRequestId && !isCpUser) {
          // we are going to check if the client portal users lists contains any Client users (as opposed to TeamMembers)
          // if not we will generate a signer using the primary email address
          this.createSignersFromPrimaryEmail(
            { first_name, last_name, name, emails },
            alphabetizedClientPortalUsers,
            signersResponse,
            clientTypes.client
          );
        }

        // now we will check the 'related clients' i.e spouse/dependents
        if (clients.length > 0 || client_for.length > 0) {
          const relatedClientsObs = {};
          let relatedUsers = [];
          let allRelatedClients = uniqBy([...clients, ...client_for], "id");

          // so for each related client, we are going to create a rxjs observable to get that client's info
          allRelatedClients?.forEach((client) => {
            relatedClientsObs[client.id] = getClientCollaborators(client.id);
          });

          // run the forkjoin for our grouped observables
          forkJoin(relatedClientsObs).subscribe((res) => {
            allRelatedClients.forEach((client) => {
              const clientType = client.relationship_type;
              const clientRelationship =
                clientType === "spouse" || clientType === "dependent" ? clientTypes.client : clientTypes.other;
              if (clientType === "referral") return;
              // for each client we are going to check the client portal users
              const clientUsers = res[client.id]?.clients?.users
                ?.filter((user) => user.role === "Client")
                .map((user) => ({ ...user, clientType: clientRelationship }));

              const { emails, first_name, is_business, last_name, name } = res[client.id]?.clients;
              // and we are going to make sure there is a CP user for each one, otherwise we do the same as above
              // and create a signer with primary email
              if (!is_business && !this.props.clientRequestId && !isClient(this.props.context)) {
                this.createSignersFromPrimaryEmail(
                  { first_name, last_name, name, emails },
                  clientUsers,
                  signersResponse,
                  clientRelationship
                );
              }
              relatedUsers = [...relatedUsers, ...clientUsers];
            });

            // sort all the related users (and signers) alphabetically
            relatedUsers = [...relatedUsers, ...signingUsers].sort((a, b) => a.name.localeCompare(b.name));
            // make sure there are no duplicated in this list when we set them to state
            this.setState((prevState) => ({
              signersContext: uniqBy([...prevState.signersContext, ...relatedUsers], "id"),
            }));
          });
        } else {
          // if there were no related clients, sort just the signers
          signingUsers.sort((a, b) => a.name.localeCompare(b.name));
          // make sure there are no duplicated in this list when we set them to state
          this.setState((prevState) => ({
            signersContext: uniqBy([...prevState.signersContext, ...signingUsers], "id"),
          }));
        }
      });
    }

    if (!this.props.preAuthSigner && !this.state.isClient && this.state.isNewCrm) {
      this.newCrmSub = forkJoin({
        signersResponse: getSigners(this.props.clientId),
        clientsResponse: getClientCollaborators(this.props.clientId),
      }).subscribe(({ signersResponse, clientsResponse }) => {
        const { contacts = [], users = [] } = clientsResponse.clients;
        let matchedSigners = [];

        // if users does not include the loggedInUser then they are not
        // assigned to client, add them to users so they show in the dropdown
        const updatedUsers = users.find((user) => user.id === this.props.context.loggedInUser.id)
          ? users
          : [...users, this.props.context.loggedInUser];

        const mappedContacts = contacts.map((contact) => {
          // the contact has a matching signer already
          // exact email, first name, and last name match
          const matchingSigner = signersResponse.find(
            (signer) =>
              signer.email?.toLowerCase() === contact?.emails?.find((email) => email.is_primary)?.value.toLowerCase() &&
              signer.first_name?.toLowerCase() === contact.first_name?.toLowerCase() &&
              signer.last_name?.toLowerCase() === contact.last_name?.toLowerCase()
          );
          if (matchingSigner) matchedSigners.push(matchingSigner);

          //the contact has a client portal user
          if (contact.directory_user_id) {
            const user = updatedUsers.find((user) => user.id === contact.directory_user_id);
            if (user)
              return {
                ...user,
                contact_type: contact.contact_type,
              };
          }

          if (matchingSigner) {
            return {
              ...matchingSigner,
              name: `${contact.first_name} ${contact.last_name}`,
              is_primary: contact.is_primary,
              contact_type: contact.contact_type,
              role: "Client",
              canEdit: true,
            };
          }
          //if no CP user and no signer then flag them as no signer
          return { ...contact, no_signer: true };
        });
        /*
          since we added the contact user data to the contacts array 
          we can remove the users that are associated from the users array
          this should leave us with team members
         */
        let self = !users.find((user) => user.id === this.props.context.loggedInUser.id)
          ? this.props.context.loggedInUser
          : null;

        const filteredUsers = [...users, ...(self ? [self] : [])]
          .filter((user) => !contacts.find((contact) => contact.directory_user_id === user.id))
          .map((user) => {
            const isSelf = user.id === this.props.context.loggedInUser.id;
            return { ...user, isSelf };
          });
        const filteredSigners = signersResponse
          .filter((signer) => !matchedSigners.some((matchedSigner) => matchedSigner.id === signer.id))
          .map((signer) => ({ ...signer, role: "Client", canEdit: true }));

        this.setState(
          (prevState) => ({
            signersContext: [
              ...prevState.signersContext,
              ...mappedContacts.filter((c) => !c.no_signer),
              ...filteredSigners,
              ...filteredUsers,
            ],
          }),
          //after the set state has run we can create signers for the contacts that have no signer
          () => this.createSigners(mappedContacts.filter((contact) => contact.no_signer))
        );
      });
    }
  }

  componentWillUnmount() {
    this.clientsAndSignersFetch && this.clientsAndSignersFetch.unsubscribe();
    this.newCrmSub && this.newCrmSub.unsubscribe();
    this.oldCrmSub && this.oldCrmSub.unsubscribe();
    this.postNewCrmUserSub && this.postNewCrmUserSub.unsubscribe();
  }

  componentDidUpdate() {
    this.signingRequestIsValid();
  }

  createSigners(contacts) {
    //create an array of requests for each contact that needs a signer
    const requests = contacts
      .map((contact) => {
        const primaryEmail = contact?.emails?.find((email) => email.is_primary);
        if (primaryEmail) {
          const newSigner = {
            first_name: contact.first_name,
            last_name: contact.last_name,
            name: contact?.name || `${contact.first_name} ${contact.last_name}`,
            email: primaryEmail.value,
            client_id: Number(this.props.clientId),
          };
          return postUser(newSigner);
        }
      })
      .filter((request) => request);
    //send the new signer requests off and then add them to the signersContext
    this.postNewCrmUserSub = forkJoin(requests).subscribe((responses) => {
      const users = responses.map((user, index) => ({
        ...user,
        role: "Client",
        canEdit: true,
        contact_type: contacts[index]?.contact_type || "other",
      }));
      this.setState((prevState) => ({
        signersContext: [...prevState.signersContext, ...users],
      }));
    }, handleError);
  }

  setSignersContext = (signersContext) => {
    this.setState({ signersContext: [...signersContext] });
  };
  setSignerTypesContext = (signerTypesContext) => {
    this.setState({ signerTypesContext: [...signerTypesContext] });
  };

  setEsignContext = (esignContext) => {
    this.setState({ esignContext: { ...esignContext } });
  };
  setScrollTo = (scrollTo) => {
    this.setState({ scrollTo: { ...scrollTo } });
  };

  setSelectedSignerIds = (selectedSignerIds) => {
    this.setState({ selectedSignerIds: [...selectedSignerIds] });
  };

  setSelectedSigner = (signer) => {
    this.setState({ selectedSigner: signer });
  };

  getSelectedSigners = () => {
    const selectContext = this.props.isTemplateMode ? this.state.signerTypesContext : this.state.signersContext;
    return selectContext
      .filter((signer) => this.state.selectedSignerIds?.includes(signer.id))
      .map((signer) =>
        this.state.isTemplate
          ? {
              ...signer,
              assignedTo: this.state.signerTypesContext?.find((type) => type?.assignedTo?.id === signer?.id),
            }
          : signer
      );
  };

  getSignedKbas = (docId) => {
    this.props.cancelWhenUnmounted(
      getSignedKba(docId).subscribe((signedDoc) => {
        if (signedDoc.message === "Completed file not generated yet") {
          toasts.infoToast("eSign document not generated yet. Please try again later.");
        } else {
          this.setState({ signedSVGs: signedDoc.svg_urls });
        }
      })
    );
  };

  populateSignerNames = (signingLocations, collaboratorsList) => {
    if (signingLocations.length > 0 && collaboratorsList.length > 0) {
      const clientCollaborators = signingLocations.reduce((collaborators, { signatory_user_id }) => {
        const matches = collaboratorsList.find((collaborator) => collaborator.key === signatory_user_id);
        if (matches) {
          collaborators[signatory_user_id] = matches.value;
        }
        return collaborators;
      }, {});

      this.setState({ clientCollaborators });
    }
  };

  loadSigningLocations = (signingId) => {
    const { documentId, cancelWhenUnmounted } = this.props;
    const { clientCollaborators } = this.state;

    cancelWhenUnmounted(
      getSigningLocations(documentId, signingId).subscribe(({ signing_requests }) => {
        const signingLocations = signing_requests.signing_locations.map((signingLocation) => {
          return {
            ...signingLocation,
            x: translateXfromPDF(signingLocation.x),
            y: translateYfromPDF(signingLocation.y),
            hasBeenSigned: !!signingLocation.value,
          };
        });
        this.setState({ signingLocations, documentCompleted: signing_requests.is_complete });
        this.populateSignerNames(signingLocations, clientCollaborators);
      }, handleError)
    );
  };

  createOrGetSigningExperience = () => {
    const { documentId, documentType, cancelWhenUnmounted, isTemplateMode, esignTemplate } = this.props;

    const idToUse = isTemplateMode ? esignTemplate.file_id : documentId;
    if (documentType === "file" && !this.state.signingSVGs && !idToUse) {
      this.setState({
        esignError: true,
      });
    }
    if (documentType === "file" && !this.state.signingSVGs && idToUse) {
      cancelWhenUnmounted(
        from(getFileEsignSvgs(idToUse, isTemplateMode)).subscribe(
          ({ urls, pdfs }) => {
            if (featureEnabled("esign_pdf")) {
              const signingSVGs = pdfs.urls.map((url) => ({ url }));
              this.setState({ signingSVGs });
            } else {
              const signingSVGs = urls.map((url) => ({ url }));
              this.setState({ signingSVGs });
            }
          },
          (error) => {
            this.setState({
              esignError: true,
            });
            handleError(error);
          }
        )
      );
    } else if (documentType === "letter") {
      const { signingId, signingLocations } = this.state;
      const getSigningExperience = signingId
        ? getSigningDocPages(signingId, documentId)
        : createSigningDocs(documentId, documentType);

      cancelWhenUnmounted(
        getSigningExperience.subscribe((signingExp) => {
          const { id, pages, consented_to_electronic_records } = signingExp.esign_docs;
          this.setState({ signingId: id, signingSVGs: pages, consented: consented_to_electronic_records });
          if (signingLocations.length === 0) {
            this.loadSigningLocations(id);
          } else {
            //Remove signing locations on pages that don't exist
            this.deleteSigningLocations(
              signingLocations.filter((signingLocation) => signingLocation.page >= pages.length)
            );
          }
        }, handleError)
      );
    }
  };

  setClientCollaborators = (clientId) => {
    const { context, cancelWhenUnmounted } = this.props;
    if (this.props.preAuthSigner) return;
    cancelWhenUnmounted(
      getClientCollaborators(clientId).subscribe((response) => {
        const clientCollaborators = response.clients.users
          .filter((user) => user.id !== context.loggedInUser.id && user.role === "Client")
          .reduce((collaborators, user) => {
            collaborators[user.id] = user.name;
            return collaborators;
          }, {});
        this.setState({ clientCollaborators });
      }, handleError)
    );
  };

  updateConsent = (consented) => {
    this.setState({ consented });
  };

  verifyKba = (verified) => {
    this.setState({ isKbaVerified: verified });
    this.props.onVerifyKba && this.props.onVerifyKba(verified);
  };

  openClientModal = () => {
    SystemJS.import("clients-ui!sofe").then((clients) =>
      clients.showInviteClientModal(this.props.clientId, this.collaboratorsUpdated)
    );
  };

  collaboratorsUpdated = (addedUserId) => {
    this.setClientCollaborators(this.props.clientId);
    this.setState({ selectSignerNewUser: addedUserId });
  };

  resetAddedUsers = () => {
    this.setState({ selectSignerNewUser: [] });
  };

  closeCancel = () => {
    this.setState({ showEsignConfirmCloseModal: true });
  };

  setClientRequestData = (clientRequestData) => {
    this.setState({ clientRequestData });
  };

  setSigningLocations = (signingLocations, clientNameObj) => {
    const signingState = { signingLocations };
    if (clientNameObj) {
      Object.assign(signingState, {
        clientCollaborators: { ...this.state.clientCollaborators, ...(clientNameObj && clientNameObj) },
      });
    }
    this.setState(signingState, () => {
      this.onSelectSigner();
    });
  };

  addSigningLocations = (signingLocationsToAdd) => {
    this.setState((prev) => ({ signingLocations: [...prev.signingLocations, ...signingLocationsToAdd] }));
  };

  deleteSigningLocations = (signingLocationsToDelete) => {
    const signingObjects = this.state.signingLocations;
    signingLocationsToDelete.forEach((toDelete) => {
      const index = findIndex(signingObjects, (item) => item.id === toDelete.id);
      signingObjects.splice(index, 1);
    });

    this.setSigningLocations(signingObjects);
  };

  setLoggedInUserSignature = (loggedInUserSignature) => {
    this.setState({ loggedInUserSignature, nextLocation: null }, () => {
      this.state.markFieldAsSigned();
      this.shouldShowSignatureEntryModal(false);
    });
  };

  shouldShowSignatureEntryModal = (
    showSignatureEntryModal = false,
    isForType = signingFieldTypes.SIGNATURE,
    markFieldAsSigned = () => {},
    signAndSend = false
  ) => {
    this.setState({
      showSignatureEntryModal,
      signAndSend,
      signatureEntryModalType: showSignatureEntryModal ? isForType : null,
      markFieldAsSigned,
    });
    if (!showSignatureEntryModal) {
      fromSigningObservable.next({ action: "CANCEL" });
    } else {
      fromSigningObservable.next({ action: "SIGNATURE" });
    }
  };

  isLoggedInUser = (id) => {
    return id === this.props.context.loggedInUser.id;
  };

  resetEsignSent = () => {
    this.setState({ esignSent: false });
  };

  completeDocumentAsClient = () => {
    const {
      documentId,
      clientRequestId,
      clientId,
      resolutionCaseId,
      closeModal,
      inlineMode,
      signerId,
      cancelWhenUnmounted,
      documentType,
    } = this.props;
    const { signingId, signingLocations, loggedInUserSignature, consented } = this.state;

    this.setState({ esignSent: true });
    const consentedLocations =
      documentType === "letter"
        ? signingLocations.map((location) => ({
            ...location,
            consented_to_electronic_records: this.state.consented,
          }))
        : {
            consented_to_electronic_records: consented,
            signing_locations: createEsignSignatureInfo(
              loggedInUserSignature,
              signingLocations,
              this.props.context.loggedInUser
            ),
          };

    if (!this.signingRequestIsValid()) {
      //Invalid, do not send request
      toasts.infoToast("Make sure to complete all the signing fields");
      return;
    }

    signAllUserLocations(
      documentId,
      signingId,
      consentedLocations,
      loggedInUserSignature,
      signerId,
      closeModal,
      this.props.preAuthSigner,
      this.resetEsignSent
    );

    if (this.allUsersHaveSigned(signingLocations, false) && documentType === "letter") {
      cancelWhenUnmounted(
        // Mark client request as 'needs_review'
        getClientRequest(clientId, resolutionCaseId, clientRequestId).subscribe((response) => {
          updateClientRequest(clientId, resolutionCaseId, clientRequestId, {
            client_requests: { ...response.client_requests, status: "needs_review" },
          }).subscribe(noop, handleError);

          closeModal("needs_review");
          toasts.successToast("Your signed document was successfully sent");
        }, handleError)
      );
    }

    if (!inlineMode && !this.props.preAuthSigner) {
      closeModal();
    }
  };

  allUsersHaveSigned = (signingLocations, includeCurrentUser = true) => {
    return (
      signingLocations
        .filter(
          (signingLocation) =>
            includeCurrentUser || !userMatchesSigningLocation(signingLocation, this.state.loggedInUserSignature)
        )
        .filter((signingLocation) => !signingLocation.value).length === 0
    );
  };

  /**
   * Make sure practitioner has signed and that all signature fields on page have been assigned
   */
  signingRequestIsValid = () => {
    const { signingLocationsAreDraggable, signingLocations, loggedInUserSignature, signingRequestIsValid } = this.state;
    let isValid = true;
    let practitionerMustSign = false;
    let errorMessage = "";

    if (!signingLocationsAreDraggable) {
      const currentUserLocations =
        signingLocations?.filter((location) =>
          this.isLoggedInUser(location.signer_id ? location.signer_id : location.signatory_user_id)
        ) || [];
      if (currentUserLocations?.length) {
        isValid = !currentUserLocations.some(
          (signingObject) => isValid && !(signingObject.hasBeenSigned || signingObject.type === signingFieldTypes.DATE)
        );
      } else {
        isValid = false;
      }
    } else {
      if (
        signingLocations.filter((signingObject) => !userMatchesSigningLocation(signingObject, loggedInUserSignature))
          .length < 1
      ) {
        //No client signing locations placed
        isValid = false;
        errorMessage = "No client fields found. Add client fields to send request.";
      }
      signingLocations.forEach((signingObject) => {
        if (isValid && !signingObject.signatory_user_id) {
          //Need to assign field to a signer
          isValid = false;
          errorMessage = "Unassigned fields found. Assign all fields to send request.";
        } else if (
          userMatchesSigningLocation(signingObject, loggedInUserSignature) &&
          (!loggedInUserSignature.signatureText || !loggedInUserSignature.initials)
        ) {
          //Practitioner still needs to sign
          practitionerMustSign = true;
        }
      });
    }
    if (signingRequestIsValid !== isValid) {
      this.setState({ signingRequestIsValid: isValid });
    }
    if (this.state.notValidErrorMessage !== errorMessage) {
      this.setState({ notValidErrorMessage: errorMessage });
    }
    if (practitionerMustSign !== this.state.practitionerMustSign) {
      this.setState({ practitionerMustSign });
    }

    if (isValid) {
      fromSigningObservable.next({ action: "IS_VALID" });
    }
    return isValid;
  };

  finalizeSigningExperience = (clientRequestObj, notifyUsers = true) => {
    const { notificationIds, clientId, resolutionCaseId, documentId, cancelWhenUnmounted } = this.props;
    const { signingId, signingLocations, loggedInUserSignature } = this.state;

    if (!this.signingRequestIsValid()) {
      //Invalid, do not send request
      toasts.infoToast("Make sure to complete all the signing fields before sending");
      return;
    }

    if (this.currentlyFinalizingSigningExperience) {
      //prevent double clicking send now
      return;
    }
    this.currentlyFinalizingSigningExperience = true;

    let clientRequestBody = {
      client_requests: { ...clientRequestObj, pivot_id: signingId },
    };

    if (notifyUsers) {
      clientRequestBody.notifications = {
        users: notificationIds,
      };
    }

    cancelWhenUnmounted(
      createClientRequest(clientId, resolutionCaseId, clientRequestBody).subscribe((clientRequestResponse) => {
        sendClientRequest(clientId, clientRequestResponse.client_requests.id).subscribe(noop, handleError);

        cancelWhenUnmounted(
          createSigningLocations(documentId, signingId, signingLocations).subscribe((signingData) => {
            signAllUserLocations(
              documentId,
              signingId,
              signingData.signing_requests.signing_locations,
              loggedInUserSignature
            );

            sessionStorage.removeItem(`partialSigningData_${documentId}`);

            this.setState({ showClientRequestCreatedSuccessfullyModal: true });
          }, handleError)
        );
      }, handleError)
    );
  };

  onSelectSigner = () => {
    this.uniqueUserSigningMap = {};
    this.state.signingLocations.forEach((loc) => {
      const isPractitioner = loc.signatory_user_id === this.props.context.loggedInUser.id;
      const uniqueUser = !this.props.isTemplateMode ? loc.signer_id || loc.signatory_user_id : loc?.signer_type_id;
      if (uniqueUser && !isPractitioner && loc?.role !== "TeamMember") {
        isNil(this.uniqueUserSigningMap[uniqueUser])
          ? (this.uniqueUserSigningMap[uniqueUser] = 1)
          : (this.uniqueUserSigningMap[uniqueUser] += 1);
      }
    });

    this.setState({ creditsSubtracted: Object.keys(this.uniqueUserSigningMap).length });
  };

  onRemoveSignature = (userId) => {
    this.uniqueUserSigningMap[userId] -= 1;
    if (this.uniqueUserSigningMap[userId] === 0) {
      delete this.uniqueUserSigningMap[userId];
      this.setState((prevState) => {
        return { creditsSubtracted: prevState.creditsSubtracted - 1 };
      });
    }
  };

  setKbaEnabled = (kbaEnabled) => {
    this.setState({ kbaEnabled }, () => {
      this.onSelectSigner();
    });
  };

  finalizeEsignExperience = (esignData, clientPortalInvitesSent = false, errorCallback) => {
    if (!this.signingRequestIsValid()) {
      //Invalid, do not send request
      toasts.infoToast("Make sure to complete all the signing fields before sending");
      return;
    }

    if (this.currentlyFinalizingSigningExperience) {
      //prevent double clicking send now
      return;
    }
    this.currentlyFinalizingSigningExperience = true;

    const { documentId: file_id, taskId: parent_task_id, clientId: client_id, cancelWhenUnmounted } = this.props;
    const { signingLocations, loggedInUserSignature, consented: consented_to_electronic_records } = this.state;
    const signing_locations = remapSigningLocationsToRatio(signingLocations, this.state.documentSizes);
    //we should never send signer_id if it's null
    signing_locations.forEach((loc) => {
      if (isNull(loc?.signer_id)) {
        delete loc.signer_id;
      }
    });
    cancelWhenUnmounted(
      postEsignatureForDoc(
        {
          ...esignData,
          file_id,
          signing_locations,
          consented_to_electronic_records,
          parent_task_id,
          client_id: Number(client_id),
        },
        loggedInUserSignature
      ).subscribe(
        ({ esign_docs_id, task_id }) => {
          sessionStorage.removeItem(`partialSigningData_${file_id}`);
          this.setState(
            {
              showEsignRequestCreatedSuccessfullyModal: true,
              clientPortalInvitesSent: clientPortalInvitesSent,
              esign_docs_id,
              esignTaskId: task_id,
            },
            () => {
              let taskUrl = `/#/tasks/clients/${this.props.clientId}?esignTaskCreated=${task_id}`;
              toasts.successToast({
                message: clientPortalInvitesSent
                  ? "eSign request and Client Portal Invites sent and task created."
                  : "eSign request sent and task created.",
                actionText: "View Task",
                actionCallback: () => (window.location.href = taskUrl),
              });
              this.props.closeModal("sent");
            }
          );
        },
        (error) => {
          this.currentlyFinalizingSigningExperience = false;

          //402 means insufficient credits
          const insufficientCredits = error.status === 402;
          errorCallback(insufficientCredits);
          if (!insufficientCredits) {
            handleError(error);
          }
        }
      )
    );
  };

  getNext = (locations, prev = false, restart = false) => {
    const incompleteAndUnsigned = !this.state.signingRequestIsValid && !location.hasBeenSigned;
    const completeAndSigned = this.state.signingRequestIsValid && location.hasBeenSigned;
    const { scrollPosition, activeSignerFieldId } = this.state;

    if (featureEnabled("toggle_files_esign_improvements")) {
      const currentField = locations.find((loc) => loc.esigning_location_id === activeSignerFieldId);
      return locations.find((location) => {
        const isValidState = incompleteAndUnsigned || completeAndSigned;

        // For fields at same height as current field
        if (
          currentField &&
          !restart &&
          Math.abs(currentField?.ref?.current?.offsetTop - location?.ref?.current?.offsetTop) <= verticalThreshold
        ) {
          return (
            isValidState &&
            location.esigning_location_id !== activeSignerFieldId &&
            (prev
              ? location?.ref?.current?.offsetLeft < currentField?.ref?.current?.offsetLeft
              : location?.ref?.current?.offsetLeft > currentField?.ref?.current?.offsetLeft)
          );
        }

        if (prev) {
          // if there is a current field use that location, if not use scroll position
          return (
            isValidState &&
            (restart ||
              (currentField
                ? location?.ref?.current?.offsetTop < currentField?.ref?.current?.offsetTop
                : scrollPosition - 32 > location?.ref?.current?.offsetTop))
          );
        } else {
          return isValidState && (restart || scrollPosition < location?.ref?.current?.offsetTop);
        }
      });
    } else {
      return locations.find((location) => {
        if (prev) {
          return scrollPosition - 32 > location?.ref?.current?.offsetTop;
        } else {
          return (
            (restart || scrollPosition < location?.ref?.current?.offsetTop) &&
            (incompleteAndUnsigned || completeAndSigned)
          );
        }
      });
    }
  };

  getScrollableLocations = () => {
    if (this.state.isClient || this.props.preAuthSigner) {
      return this.state.signingLocations.filter(
        (loc) => userMatchesSigningLocation(loc, this.state.loggedInUserSignature) && loc.type !== "date"
      );
    } else {
      return this.state.signingLocations.filter((loc) => loc.type !== "date");
    }
  };

  goToSigningLocations = (initial = false, scrollContainer) => {
    this.setState({ scrollEnded: false, buttonScroll: true, prevLocation: !initial });
    const locations = this.getScrollableLocations();
    if (!locations?.length > 0) return;
    if (featureEnabled("toggle_files_esign_improvements")) {
      locations.sort((a, b) => {
        const aTop = a?.y || a?.ref?.current?.offsetTop;
        const bTop = b?.y || b?.ref?.current?.offsetTop;
        const verticalDiff = aTop - bTop;
        // If vertical positions are within threshold (using y coordinates from the data)
        if (Math.abs(verticalDiff) <= verticalThreshold) {
          // Sort left to right using x coordinates from the data
          const aLeft = a?.x || a?.ref?.current?.offsetLeft;
          const bLeft = b?.x || b?.ref?.current?.offsetLeft;
          return aLeft - bLeft;
        }
        return verticalDiff;
      });
    } else {
      locations.sort((a, b) => a?.ref?.current?.offsetTop - b?.ref?.current?.offsetTop);
    }

    if (initial || locations.length === 1) {
      let initialLoc = locations[0];
      const { current: initialEl } = initialLoc?.ref;
      const { current: scrollEl } = scrollContainer;

      initialEl?.scrollIntoView({ behavior: "smooth", block: "center" });

      const scrollRect = scrollEl?.getBoundingClientRect();
      const locRect = initialEl?.getBoundingClientRect();
      const windowScroll = window.scrollY || window.pageYOffset;

      const initialLocAtDocumentStart =
        locRect.top + windowScroll - (scrollRect.top + windowScroll) <= scrollRect.height * 0.535;
      const initialLocHasScrolledAboveButtons = locRect.top + windowScroll < scrollEl.scrollTop;

      if (initialLocAtDocumentStart && !initialLocHasScrolledAboveButtons)
        this.setState({ scrollPosition: window.innerHeight * 0.535, scrollEnded: true, buttonScroll: false });

      if (locations.length === 1) this.setState({ prevLocation: null });
      this.setState({ nextLocation: initialLoc, activeSignerFieldId: initialLoc.esigning_location_id });
      return;
    } else {
      let nextLoc = this.getNext(locations);
      //try only one more time to restart the array
      if (!nextLoc) nextLoc = this.getNext(locations, false, true);
      if (nextLoc) {
        nextLoc?.ref?.current.scrollIntoView({ behavior: "smooth", block: "center" });
      } else {
        locations[0]?.ref?.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }

      if (nextLoc?.esigning_location_id === locations[0].esigning_location_id) {
        this.setState({ clearPrev: true });
      }
      this.setState({
        nextLocation: nextLoc || locations[0],
        activeSignerFieldId: nextLoc?.esigning_location_id || locations[0].esigning_location_id,
      });
      return;
    }
  };

  goToPrevSigningLocations = (initial) => {
    this.setState({ scrollEnded: false, buttonScroll: true, prevLocation: !initial });
    const locations = this.getScrollableLocations();
    if (!locations?.length) return;
    if (!featureEnabled("toggle_files_esign_improvements")) {
      locations.sort((a, b) => b?.ref?.current?.offsetTop - a?.ref?.current?.offsetTop);
    }

    const nextLoc = this.getNext(locations, true);
    if (nextLoc) nextLoc?.ref?.current.scrollIntoView({ behavior: "smooth", block: "center" });
    if (nextLoc?.esigning_location_id === locations.at(-1).esigning_location_id) {
      this.setState({ clearPrev: true });
    }
    this.setState({ nextLocation: nextLoc, activeSignerFieldId: nextLoc?.esigning_location_id });
  };

  getScrollPosition = (e) => {
    if (this.state.signingLocationsAreDraggable) return;
    this.state.scrollEnded && this.setState({ scrollEnded: false });
    if (!this.state.buttonScroll) this.state.nextLocation && this.setState({ nextLocation: null });
    const scrollPosition = e?.target?.scrollTop + e.target.clientHeight / 2;

    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
      if (this.state.clearPrev) {
        this.setState({ prevLocation: null, clearPrev: false });
      }
      this.setState({ scrollEnded: true, buttonScroll: false, scrollPosition });
    }, 100);
  };

  determineDocumentLoaded = () => {
    const lastSigningLocation = cloneDeep(
      this.state.isClient
        ? this.state.signingLocations.filter((loc) => userMatchesSigningLocation(loc, this.state.loggedInUserSignature))
        : this.state.signingLocations
    ).sort((a, b) => b.page - a.page);

    this.setState((prevState) => {
      return {
        loadedPages: prevState.loadedPages + 1,
        signingPagesLoaded: lastSigningLocation?.[0]?.page
          ? prevState.loadedPages + 1 > lastSigningLocation[0].page
          : lastSigningLocation.length,
      };
    });
  };

  openSignerDropdown = (role) => {
    const matchedLocation = this.state.signingLocations.find((loc) => loc.signer_type?.id === role.id);
    this.setState({ showSignerDropdown: matchedLocation });
  };
  resetSignerDropdown = () => {
    this.setState({ showSignerDropdown: false });
  };

  assignSignerTypes = (signer, role, filteredSigningLocations) => {
    const { signingLocations, signerTypesContext } = this.state;
    const locations = filteredSigningLocations ? filteredSigningLocations : signingLocations;
    if (this.props.isTemplateMode) {
      const updatedLocations = locations.map((loc) =>
        loc.signer_type_id === signer.id ? { ...loc, signer_type_id: null, signer_type_name: null } : loc
      );
      return this.setState({ signingLocations: updatedLocations });
    }
    if (!this.state.isTemplate) return filteredSigningLocations && this.setSigningLocations(filteredSigningLocations);
    const updatedLocations = locations.map((loc) => {
      if (role?.id && loc?.signer_type_id === role?.id && signer) {
        return {
          ...loc,
          signatory_user_id: signer.user_id || signer.id,
          ...("user_id" in signer && { signer_id: signer.id }),
          role: signer.role,
          isTeamMember: signer.role === "TeamMember",
        };
      }
      return loc;
    });
    const mappedSignerTypes = signerTypesContext.map((signerType) => {
      if (signerType?.id === role?.id) {
        return { ...signerType, assignedTo: signer.id ? signer : undefined };
      }
      return signerType;
    });
    this.setState({ signingLocations: updatedLocations, signerTypesContext: mappedSignerTypes }, () =>
      this.onSelectSigner()
    );
  };

  applyTemplate = (template) => {
    const uniqueLocations = uniqBy(template.signing_locations, (loc) => loc?.signer_type_id);
    const uniqueRoles = this.state.allSignerTypes.filter((type) =>
      uniqueLocations.find((loc) => loc.signer_type_id === type.id)
    );

    const hasSelf = template.signing_locations.find((loc) => !loc?.signer_type_id);
    const locations = template.signing_locations.map((loc) => {
      const signerType = this.state.allSignerTypes.find((type) => type.id === loc.signer_type_id);
      const baseLocation = signatureElements.find((el) => el.type === loc.type);
      return { id: uuid(), ...loc, ...baseLocation, role: loc?.role || signerType?.user_role };
    });
    this.setState({
      isTemplate: true,
      signingLocations: locations,
      signerTypesContext: uniqueRoles,
      hasSelf,
    });
    this.setState((prevState) => ({
      esignContext: {
        ...prevState.esignContext,
        title: template.title,
        description: template.description,
        templateId: template.id,
      },
    }));
    const remappedSigningLocations = remapAllSigningLocationsFromRatio(locations, this.state.documentSizes);
    this.setState({ signingLocations: remappedSigningLocations.filter((loc) => loc) }, () =>
      this.setKbaEnabled(template?.kba_enabled)
    );
    if (remappedSigningLocations.filter((loc) => loc).length !== remappedSigningLocations.length) {
      toasts.infoToast("This document does not have the correct number of pages for this template.");
    } else {
      toasts.successToast(`Template "${template.template_name}" was applied successfully.`);
    }
  };

  render() {
    const { inlineMode, esignDocument, context, title, documentId, signerId, isTemplateMode } = this.props;
    const {
      isKbaVerified = false,
      signatureEntryModalType,
      loggedInUserSignature,
      primaryClientClientPortalUsers,
      showSignatureEntryModal,
      signersContext,
      setSignersContext,
      signerTypesContext,
      setSignerTypesContext,
      esignContext,
      setEsignContext,
      esignSent,
      scrollTo,
      setScrollTo,
      selectedSignerIds,
      setSelectedSignerIds,
      getSelectedSigners,
      practitionerMustSign,
      showEsignConfirmCloseModal,
      notValidErrorMessage,
      isNewCrm,
      selectedSigner,
      setSelectedSigner,
      activeSignerFieldId,
      setActiveSignerFieldId,
    } = this.state;

    const jsEsignDocument = esignDocument
      ? {
          id: documentId,
          title: title,
          kbaTimeLimit: esignDocument.kba_time_limit,
          attemptsRemaining: esignDocument.kba_attempts_remaining,
          originalPdfDownloadUrl: esignDocument.original_pdf_download_url,
          originalPdfUrl: esignDocument.original_pdf_url,
          userManuallySigned: esignDocument.user_manually_signed,
          kbaEnabled: context.loggedInUser.role === "Client" ? esignDocument.kba_enabled : false,
          completed: esignDocument.completed,
          userElectronicallySigned: esignDocument.consented_to_electronic_records,
          kbaAttemptsAllowed: context.loggedInUser.role === "Client" ? esignDocument.kba_attempts_allowed : false,
        }
      : { id: documentId, title, kbaEnabled: false };

    const hasVerified = esignDocument && jsEsignDocument.kbaEnabled ? isKbaVerified : !this.props.taxPrepManualSign;

    return (
      <SigningContext.Provider
        value={{
          isNewCrm,
          signersContext,
          signerTypesContext,
          setSignersContext,
          setSignerTypesContext,
          scrollTo,
          setScrollTo,
          selectedSignerIds,
          setSelectedSignerIds,
          getSelectedSigners,
          selectedSigner,
          setSelectedSigner,
          activeSignerFieldId,
          setActiveSignerFieldId,
        }}
      >
        <EsignContext.Provider value={{ esignContext, setEsignContext, practitionerMustSign, notValidErrorMessage }}>
          <div>
            <link
              href="https://fonts.googleapis.com/css?family=Allura|Courgette|Mr+Dafoe|Shadows+Into+Light+Two"
              rel="stylesheet"
            />
            {inlineMode && (
              <EsignInline
                {...this.state}
                {...this.props}
                esignDocument={jsEsignDocument}
                hasVerified={hasVerified}
                setSigningLocations={this.setSigningLocations}
                openClientModal={this.openClientModal}
                setDocumentSizes={this.setDocumentSizes}
                onSelectSigner={this.onSelectSigner}
                onRemoveSignature={this.onRemoveSignature}
                verifyKba={this.verifyKba}
                showSignatureEntryModal={showSignatureEntryModal}
                shouldShowSignatureEntryModal={this.shouldShowSignatureEntryModal}
                setLoggedInUserSignature={this.setLoggedInUserSignature}
                updateConsent={this.updateConsent}
                signatureEntryModalType={signatureEntryModalType}
                loggedInUserSignature={loggedInUserSignature}
                /* Esign-inline Specific */
                context={context}
                /* Tax Prep Specific */
                hideEsignRequest
              />
            )}
            {!inlineMode && (
              <EsignModal
                {...this.state}
                {...this.props}
                title={isTemplateMode ? esignContext?.template_name : this.props?.title}
                isTemplateMode={this.props.isTemplateMode}
                applyTemplate={this.applyTemplate}
                isTemplate={this.state.isTemplate}
                assignSignerTypes={this.assignSignerTypes}
                signerTypes={this.state.signerTypes}
                openSignerDropdown={this.openSignerDropdown}
                showSignerDropdown={this.state.showSignerDropdown}
                resetSignerDropdown={this.resetSignerDropdown}
                hasSelf={this.state.hasSelf}
                documentSizes={this.state.documentSizes}
                esignDocument={jsEsignDocument}
                esignSent={esignSent}
                hasVerified={hasVerified}
                loggedInUserSignature={loggedInUserSignature}
                openClientModal={this.openClientModal}
                onRemoveSignature={this.onRemoveSignature}
                onSelectSigner={this.onSelectSigner}
                primaryClientClientPortalUsers={primaryClientClientPortalUsers}
                resetAddedUsers={this.resetAddedUsers}
                setDocumentSizes={this.setDocumentSizes}
                setLoggedInUserSignature={this.setLoggedInUserSignature}
                setSigningLocations={this.setSigningLocations}
                addSigningLocations={this.addSigningLocations}
                showSignatureEntryModal={showSignatureEntryModal}
                shouldShowSignatureEntryModal={this.shouldShowSignatureEntryModal}
                signatureEntryModalType={signatureEntryModalType}
                updateConsent={this.updateConsent}
                verifyKba={this.verifyKba}
                /* Modal specific */
                completeDocumentAsClient={this.completeDocumentAsClient}
                closeCancel={this.closeCancel}
                setClientRequestData={this.setClientRequestData}
                finalizeSigningExperience={this.finalizeSigningExperience}
                finalizeEsignExperience={this.finalizeEsignExperience}
                kbaEnabled={this.state.kbaEnabled}
                setKbaEnabled={this.setKbaEnabled}
                preAuthSigner={this.props.preAuthSigner}
                signerId={signerId}
                goToSigningLocations={this.goToSigningLocations}
                goToPrevSigningLocations={this.goToPrevSigningLocations}
                getScrollPosition={this.getScrollPosition}
                scrollEnded={this.state.scrollEnded}
                nextLocation={this.state.nextLocation}
                prevLocation={this.state.prevLocation}
                //we may need these later
                determineDocumentLoaded={this.determineDocumentLoaded}
                signingPagesLoaded={this.state.signingPagesLoaded}
              />
            )}
            <EsignConfirmCloseModal
              closeModal={() => {
                // NOTE this closes the modal but keeps esign template overlay open for editing
                this.setState({ showEsignConfirmCloseModal: false });
              }}
              showModal={showEsignConfirmCloseModal}
              newTemplate={this.props.esignTemplate?.new_template}
              isTemplateMode={this.props.isTemplateMode}
              closeModalAndOverlay={() => {
                this.setState({ showEsignConfirmCloseModal: false });
                this.props.closeModal(this.state.esignContext);
              }}
              closeModalAndDelete={() => {
                // if this.props.esignTemplate.new_template is true then it's a new template or new duplicate and we want to delete
                this.setState({ showEsignConfirmCloseModal: false });
                this.props.closeModal(this.state.esignContext, true);
              }}
            />
          </div>
        </EsignContext.Provider>
      </SigningContext.Provider>
    );
  }
}

EsignContainer.defaultProps = {
  inlineMode: false,
  taxPrepManualSign: false,
  hideEsignRequest: false,
};

const DragDropContext = (EsignContainer) => (props) =>
  (
    <DndProvider backend={HTML5Backend}>
      <EsignContainer {...props} />
    </DndProvider>
  );

const DecoratedSigningModal = DragDropContext(EsignContainer);

export default DecoratedSigningModal;

export function showSigningModal({
  context,
  clientId,
  signingId,
  title,
  clientRequestId,
  modalClosed,
  documentType,
  documentId,
  resolutionCaseId,
  notificationIds,
  esignDocument,
  signer,
  inlineMode,
  taxPrepManualSign,
  preAuthSigner = false,
  signerId = null,
}) {
  return SystemJS.import("feature-toggles!sofe")
    .then((ft) => ft.fetchFeatureToggles(...featureToggles))
    .then(() => {
      return new Promise((resolve) => {
        const div = document.createElement("div");
        document.body.appendChild(div);
        const root = createRoot(div);
        if (localStorage.getItem("sofe-inspector")) {
          signer = {
            firstName: "John",
            lastName: "Smith",
            address1: "1234 Any Street",
            address2: "",
            city: "Any City",
            state: { key: "UT", value: "Utah" },
            postalCode: "84000",
            birthDate: "10/10/1990",
            ssn: "441-41-4414",
          };
        }

        root.render(
          <DecoratedSigningModal
            context={context}
            signer={signer}
            clientId={clientId}
            signingId={signingId}
            title={title}
            esignDocument={esignDocument}
            clientRequestId={clientRequestId}
            documentType={documentType}
            documentId={documentId}
            resolutionCaseId={resolutionCaseId}
            notificationIds={notificationIds}
            inlineMode={inlineMode}
            taxPrepManualSign={taxPrepManualSign}
            // onKbaFailClose={}
            closeModal={(success) => {
              root.unmount();
              div.parentNode.removeChild(div);
              resolve();
              modalClosed(success);
            }}
            preAuthSigner={preAuthSigner}
            signerId={signerId}
          />
        );
      });
    });
}

const signingModalWithError = ErrorBoundary({ featureName: "signing-ui" })(DecoratedSigningModal);

const reactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: signingModalWithError,
});

export const SigningParcel = {
  ...reactLifecycles,
  bootstrap: [
    () =>
      SystemJS.import("feature-toggles!sofe").then((ft) => {
        return ft.fetchFeatureToggles(...featureToggles);
      }),
    reactLifecycles.bootstrap,
  ],
};

export const toSigningObservable = new Subject();
export const fromSigningObservable = new Subject();
