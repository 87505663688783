import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import ClientRequest from "./letter-client-request.component.js";
import EsignRequest from "./letter-esign-request.component.js";
import { EsignContext, SigningContext } from "../../signing-context.js";

import DraggableMenuItem from "./letter-draggable-menu-item.component.js";

import styles from "../../signing-modal.styles.css";

export default class LettersSidePanel extends React.Component {
  static propTypes = {
    tabs: PropTypes.object.isRequired,
    activeTab: PropTypes.string.isRequired,
    signingId: PropTypes.string,
    resolutionCaseId: PropTypes.string,
    title: PropTypes.string.isRequired,
    signingRequestIsValid: PropTypes.bool.isRequired,
    clientRequestData: PropTypes.object,
    showClientRequestCreatedSuccessfullyModal: PropTypes.bool.isRequired,
    signatureElements: PropTypes.array.isRequired,
    context: PropTypes.object.isRequired,
    setActiveTab: PropTypes.func.isRequired,
    setClientRequestData: PropTypes.func.isRequired,
    finalizeSigningExperience: PropTypes.func.isRequired,
    finalizeEsignExperience: PropTypes.func,
    requireClientRequest: PropTypes.bool.isRequired,
  };

  renderClientRequest = () => {
    const {
      clientId,
      clientRequestData,
      resolutionCaseId,
      finalizeEsignExperience,
      finalizeSigningExperience,
      requireClientRequest,
      setClientRequestData,
      showClientRequestCreatedSuccessfullyModal,
      signingId,
      signingRequestIsValid,
      signingLocations,
      title,
    } = this.props;

    return (
      <SigningContext.Consumer>
        {({ signersContext }) => (
          <EsignContext.Consumer>
            {({ esignContext, setEsignContext }) =>
              requireClientRequest ? (
                <ClientRequest
                  clientId={clientId}
                  clientRequestData={clientRequestData}
                  resolutionCaseId={resolutionCaseId}
                  esignContext={esignContext}
                  finalizeSigningExperience={finalizeSigningExperience}
                  setClientRequestData={setClientRequestData}
                  setEsignContext={setEsignContext}
                  showClientRequestCreatedSuccessfullyModal={showClientRequestCreatedSuccessfullyModal}
                  signersContext={signersContext}
                  signingId={signingId}
                  signingLocations={signingLocations}
                  signingRequestIsValid={signingRequestIsValid}
                  title={title}
                />
              ) : (
                <EsignRequest
                  finalizeEsignExperience={finalizeEsignExperience}
                  signingRequestIsValid={signingRequestIsValid}
                  title={title}
                />
              )
            }
          </EsignContext.Consumer>
        )}
      </SigningContext.Consumer>
    );
  };

  renderDraggableSignatures = () => {
    const { activeTab, context, signatureElements } = this.props;
    return (
      <div className={`${styles.draggableElementsRow}`}>
        <div className={`${styles.draggableElementsContainer}`}>
          {signatureElements.map((signatureElement, pos) => (
            <DraggableMenuItem activeTab={activeTab} context={context} key={pos} signatureElement={signatureElement} />
          ))}
        </div>
      </div>
    );
  };

  render() {
    const { activeTab, setActiveTab, tabs } = this.props;

    return (
      <div className={`${styles.darkColumn}`}>
        <div className={`${styles.flexToTopContainer}`}>
          <div className={`${styles.darkTabStrip}`}>
            {map(tabs, (tab, key) => {
              return (
                <div
                  key={key}
                  onClick={setActiveTab.bind(null, tab)}
                  className={`${styles.tabStripElement} ${activeTab === tab ? styles.selected : null}`}
                >
                  {tab}
                </div>
              );
            })}
          </div>
          {activeTab === tabs.REQUEST ? this.renderClientRequest() : this.renderDraggableSignatures()}
        </div>
      </div>
    );
  }
}
