import React, { useEffect } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import styles from "./letter-draggable-menu-item.styles.css";
import { tabs } from "../../constants.js";

export default function LetterDraggableMenuItem({ signatureElement, activeTab, context }) {
  const [{ opacity }, drag, preview] = useDrag(
    () => ({
      type: "dragField",
      item: {
        ...signatureElement,
        border: activeTab === tabs.SIGNATURE ? "var(--cp-color-pill-team-text)" : "var(--cp-color-app-primary)",
        color: activeTab === tabs.SIGNATURE ? "var(--cp-color-pill-team-bg)" : "var(--cp-color-pill-bg)",
        signatory_user_id: activeTab === tabs.SIGNATURE ? context.loggedInUser.id : null,
        value: null,
        font: null,
        completed_at: null,
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
      previewOptions: {
        anchorX: 0,
        anchorY: 0,
      },
    }),
    [signatureElement, activeTab, context]
  );

  useEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }, [preview, signatureElement, activeTab]);

  return (
    <>
      <div
        ref={drag}
        style={{
          opacity: opacity,
          fontSize: 25,
          fontWeight: "bold",
          cursor: "move",
          width: signatureElement.type === "signature" ? "100%" : null,
        }}
        className={`cps-caption cps-wt-bold cps-depth-1 ${styles.draggableElement}`}
      >
        <div
          className={`${activeTab === tabs.SIGNATURE ? styles.dragHandleLargeGreen : styles.dragHandleLargeBlue}`}
        ></div>
        <i
          className={`${signatureElement.icon} ${styles.iconSpace} cps-icon ${
            activeTab === tabs.RECIPIENTS ? "cps-info" : "cps-color-green"
          }`}
        ></i>
        {signatureElement.text}
      </div>
    </>
  );
}
