import React from "react";
import PropTypes from "prop-types";

import styles from "../../client-request/client-request.styles.css";
import Title from "../../client-request/title/title.component.js";
import DueDate from "../../client-request/due_at/due-date.component.js";
import Reminders from "../../client-request/reminders/reminders.component.js";
import DescriptionRTE from "../../client-request/description/description.component.js";
import { isEqual } from "lodash";

export default class LetterClientRequest extends React.Component {
  static propTypes = {
    signingId: PropTypes.string,
    resolutionCaseId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    finalizeSigningExperience: PropTypes.func.isRequired,
    signingRequestIsValid: PropTypes.bool.isRequired,
    setClientRequestData: PropTypes.func.isRequired,
    showClientRequestCreatedSuccessfullyModal: PropTypes.bool.isRequired,
    clientRequestData: PropTypes.shape({
      reminderModalDisplayed: PropTypes.bool.isRequired,
      openClientModal: PropTypes.bool.isRequired,
      requestSent: PropTypes.bool.isRequired,
      client_request: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        due_at: PropTypes.number,
        reminders: PropTypes.shape({
          reminder_interval: PropTypes.string,
          expiration_interval_days: PropTypes.string,
        }).isRequired,
        visible_to_client: PropTypes.bool.isRequired,
        relationships: PropTypes.shape({
          for: PropTypes.shape({
            type: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
        pivot_type: PropTypes.string.isRequired,
        pivot_id: PropTypes.string,
      }),
    }),
  };

  constructor(props) {
    super(props);

    const defaultDescription =
      "Please review and sign the letter below. It will be automatically sent to me when you've finished signing. Thanks!";

    this.state = props.clientRequestData || {
      reminderModalDisplayed: false,
      openClientModal: false,
      requestSent: false,
      client_request: {
        title: `Signing request: ${this.props.title}`,
        description: defaultDescription,
        due_at: null,
        reminders: {
          reminder_interval: null,
          expiration_interval_days: null,
        },
        visible_to_client: true,
        relationships: {
          for: {
            type: "resolution_case",
            id: this.props.resolutionCaseId,
          },
        },
        pivot_type: "esign_doc",
        pivot_id: this.props.signingId,
      },
      descriptionObj: null,
    };
  }

  componentWillUnmount() {
    this.props.setClientRequestData(this.state);
  }

  componentDidMount() {
    this.filterEsignInvites();
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.signersContext, this.props.signersContext) ||
      !isEqual(prevProps.signingLocations, this.props.signingLocations)
    ) {
      this.filterEsignInvites();
    }
    if (!isEqual(prevProps.esignContext, this.props.esignContext)) {
      this.props.setEsignContext({ ...this.props.esignContext });
    }
  }

  render() {
    return (
      <div className={`${styles.clientRequestContainer}`}>
        <div className={`${styles.clientRequestElement} cps-body`}>
          <Title setTitle={this.setTitle} title={this.state.client_request.title} />
        </div>
        <div className={`${styles.clientRequestElement}`}>
          <DueDate
            setDueDate={this.setDueDate}
            removeDueDate={this.removeDueDate}
            date={this.state.client_request.due_at}
          />
        </div>
        <div className={`${styles.clientRequestElement}`}>
          <Reminders
            reminders={this.state.client_request.reminders}
            setReminderModalDisplayed={this.setReminderModalDisplayed}
            setReminder={this.setReminder}
            removeReminder={this.removeReminder}
            reminderModalDisplayed={this.state.reminderModalDisplayed}
            chosenDescription={this.state.descriptionObj}
          />
        </div>
        <div className={`${styles.clientRequestElement}`}>
          <DescriptionRTE
            description={this.state.client_request.description}
            updateDescriptionRTE={this.updateDescriptionRTE}
          />
        </div>
        <div className={`${styles.clientRequestElement}`}>
          <button
            className={`cps-btn +primary ${
              this.props.signingRequestIsValid && !this.props.showClientRequestCreatedSuccessfullyModal
                ? null
                : "+disabled"
            }`}
            onClick={this.sendClientPortalInvites}
          >
            Send now
          </button>
        </div>
      </div>
    );
  }

  filterEsignInvites = () => {
    const filteredCollaboratorsList = this.props.signersContext?.filter((user) =>
      this.props.signingLocations?.find(
        (location) =>
          (location.signatory_user_id === user.id || location.signatory_user_id === user.user_id) &&
          location.signatory_user_id
      )
    );
    this.setState({ filteredCollaborators: filteredCollaboratorsList });
  };

  setTitle = (event) => {
    this.setState({ client_request: { ...this.state.client_request, title: event.target.value } });
  };

  setDueDate = (due_at) => {
    this.setState({ client_request: { ...this.state.client_request, due_at } });
  };

  removeDueDate = () => {
    this.setState({ client_request: { ...this.state.client_request, due_at: null } });
  };

  setReminderModalDisplayed = (displayed = false) => {
    this.setState({ reminderModalDisplayed: displayed });
  };

  setReminder = (reminder_interval, expiration_interval_days, descriptionObj) => {
    this.setState({
      client_request: { ...this.state.client_request, reminders: { reminder_interval, expiration_interval_days } },
      descriptionObj,
    });
    this.setReminderModalDisplayed(false);
  };

  removeReminder = () => {
    this.setState({
      client_request: {
        ...this.state.client_request,
        reminders: { reminderinterval: null, expiration_interval_days: null },
      },
    });
  };

  sendClientPortalInvites = () => {
    //TODO: add users to send invite modal
    const invites = this.state.filteredCollaborators.filter((user) => user.sendClientPortalInvite);
    if (invites?.length) {
      SystemJS.import("clients-ui!sofe").then((clients) =>
        clients.showInviteClientModal(
          this.props.clientId,
          this.props.finalizeSigningExperience.bind(null, this.state.client_request), //(users)
          undefined,
          false,
          false,
          false,
          "Cancel",
          false,
          undefined,
          invites
        )
      );
    } else {
      this.props.finalizeSigningExperience(this.state.client_request); //this.submit()
    }
  };
}
