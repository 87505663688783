import React, { useState, useEffect, useRef, forwardRef } from "react";
import { ResizableBox } from "react-resizable";
import { useCss, k, a, m } from "kremling";
import { CpIcon, CpTextarea, CpTooltip } from "canopy-styleguide!sofe";
import { signingFieldTypes, ResizableFieldDimensions } from "../constants";
import { userMatchesSigningLocation } from "../signing-modal.helper";

import "react-resizable/css/styles.css";
import { CompletedField } from "./completed-field.component";
import { getDefaultFieldWidth } from "src/signing-resource.helper";

// TODO move to own component
// TODO also remove forwardRef
const ResizeHandle = forwardRef((props, ref) => {
  const { handleAxis, signingObject, ...restProps } = props;
  const teamMember = signingObject?.isTeamMember;

  return (
    <div
      ref={ref}
      className="custom-resize-handle"
      {...restProps}
      onMouseDown={(e) => {
        // prevent drag behavior on resize handle
        e.stopPropagation();
        e.preventDefault();

        // now call the original onMouseDown from react-resizable
        props.onMouseDown?.(e);
      }}
      style={{
        position: "absolute",
        width: "8px",
        height: "8px",
        backgroundColor: teamMember ? "var(--cp-color-pill-team-text)" : "var(--cp-color-default-badge-bg)",
        border: `1px solid ${teamMember ? "var(--cp-color-pill-team-text)" : "var(--cp-color-default-badge-bg)"}`,
        borderRadius: "50%",
        right: "-4px",
        top: "-4px",
        cursor: "ne-resize",
        zIndex: 1000,
      }}
    />
  );
});
ResizeHandle.displayName = "ResizeHandle";

export const DraggableResizableField = ({
  fieldRef,
  activeSignerFieldId,
  context,
  documentRef,
  dropdownRef,
  handleShowToolbar,
  hasBeenSigned,
  isDateObject,
  isSignatureObject,
  markFieldAsSigned,
  openSignatureEntryModal,
  resizeHandleRef,
  setActiveSignerFieldId,
  setShowFieldHeader,
  setTextFieldValue,
  showFieldHeader,
  signerFieldProps,
  signingLocationsAreDraggable,
  signingObject,
  textFieldValue,
  updateSigningObject,
}) => {
  const scope = useCss(css);

  const defaultWidth = getDefaultFieldWidth(signingObject.type);
  const [size, setSize] = useState({
    width: signingObject?.width || defaultWidth,
    height: signingObject?.height || ResizableFieldDimensions.DEFAULT_HEIGHT,
  });
  const [maxConstraints, setMaxConstraints] = useState([400, 400]); // Default fallback in case we can't get the page
  const [showToolbar, setShowToolbar] = useState(false); // used to show toolbar once component is mounted
  const [fieldIsActive, setFieldIsActive] = useState(false);
  const isTeamMember = signingObject?.isTeamMember || signingObject?.signatory_user_role === "TeamMember";
  const isTextField = signingObject?.type === signingFieldTypes.TEXT;
  const textareaRef = useRef(null);
  const forCurrentUser = context?.loggedInUser
    ? userMatchesSigningLocation(signingObject, context.loggedInUser)
    : false;

  useEffect(() => {
    // Find the page container element
    const fieldElement = documentRef.current;
    if (fieldElement) {
      // get page size from page container so max field size is limited by page size
      const pageElement = fieldElement.closest('[id^="pageView"]');
      if (pageElement) {
        const pageWidth = pageElement.clientWidth;
        const pageHeight = pageElement.clientHeight;
        setMaxConstraints([pageWidth, pageHeight]);
      }
    }
  }, [documentRef]);

  useEffect(() => {
    // set to true to show toolbar once component is mounted
    setShowToolbar(true);
  }, []);

  useEffect(() => {
    // show toolbar when new field is dropped on page
    if (showToolbar && typeof handleShowToolbar === "function") {
      const fieldElement = fieldRef.current?.querySelector("[data-field-container]");
      if (fieldElement) {
        setShowFieldHeader(false);
        handleShowToolbar({ target: fieldElement });
      }
      setShowToolbar(false);
    }
  }, [showToolbar, handleShowToolbar]);

  const handleResize = (e, { size: newSize }) => {
    // TODO need to prevent resize if text would overflow
    setSize(newSize);
  };

  const handleResizeStop = (e, { size: newSize }) => {
    const updatedObject = {
      ...signingObject,
      width: newSize.width,
      height: newSize.height,
    };
    updateSigningObject(updatedObject);
  };

  const handleTextChange = (e) => {
    if (!isTextField || !forCurrentUser) return;
    const textarea = textareaRef?.current;
    if (!textarea) return;
    const wouldOverflow = textarea.scrollHeight > textarea.clientHeight;
    // TODO need to prevent resize if text would overflow

    if (!wouldOverflow) {
      setTextFieldValue(e);
      const updatedObject = {
        ...signingObject,
        value: e,
        height: signingObject.height,
        width: signingObject.width,
        x: signingObject.x,
        y: signingObject.y,
      };

      markFieldAsSigned(signingObject.esigning_location_id || signingObject.id);
      updateSigningObject(updatedObject);
    }
  };

  const handleMouseDown = (e) => {
    e.stopPropagation();
    // Check toolbar dropdown state before it can be changed by onClick
    const isDropdownOpen = dropdownRef.current?.isOpen;
    if (isDropdownOpen) {
      setFieldIsActive(true);
    } else {
      setFieldIsActive(false);
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
    if (signingObject.signed) return;
    !isDateObject && setActiveSignerFieldId(signingObject.esigning_location_id);

    // open signature entry modal if field is active and toolbar open
    if ((fieldIsActive || activeSignerFieldId) && forCurrentUser && !isDateObject && !signingObject?.signed) {
      openSignatureEntryModal();
    } else {
      handleShowToolbar(e);
    }
  };

  const handleClickShowToolbar = (e) => {
    e.stopPropagation();
    if (signingObject.signed) return;
    setActiveSignerFieldId(signingObject.esigning_location_id);
    handleShowToolbar(e);
  };

  const showActiveSignerFieldExtras =
    activeSignerFieldId === signingObject.esigning_location_id &&
    !signingObject.hasBeenSigned &&
    !signingLocationsAreDraggable;

  return (
    <div
      {...scope}
      ref={fieldRef}
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
      }}
    >
      {((showFieldHeader && signingLocationsAreDraggable) || showActiveSignerFieldExtras) && (
        <div
          className={a("custom-resizable-box-header").t(
            "header-background-teamMember",
            "header-background-client",
            isTeamMember
          )}
          style={{ maxWidth: `${size.width}px` }}
        >
          <CpTooltip text={signerFieldProps?.text}>
            <div className="header-content">
              {signerFieldProps?.icon && <CpIcon name={signerFieldProps.icon} />}
              <span className="header-text">{signerFieldProps?.text}</span>
            </div>
          </CpTooltip>
        </div>
      )}
      {
        <ResizableBox
          data-field-container
          width={size.width}
          height={size.height}
          onResize={handleResize}
          onResizeStop={handleResizeStop}
          minConstraints={[48, 16]}
          maxConstraints={maxConstraints}
          resizeHandles={signerFieldProps?.allowResize ? ["ne"] : []}
          handle={
            signerFieldProps?.allowResize ? <ResizeHandle signingObject={signingObject} ref={resizeHandleRef} /> : null
          }
        >
          <div // border around the text field
            className={a("custom-resizable-box-border").m("custom-resizable-box-border-teamMember", isTeamMember)}
            style={{ height: `${size.height}px` }}
          >
            <div // transparent background
              className={a("custom-resizable-box-background").m(
                "custom-resizable-box-background-teamMember",
                isTeamMember
              )}
            />
            {/* TODO move text field code to its own component, maybe CpSwitch?  */}
            {isTextField ? (
              <div ref={textareaRef}>
                {/* text fields should be editable until doc has been sent and signed is true */}
                {signingObject.signed ? (
                  <CompletedField
                    {...signerFieldProps}
                    context={context}
                    forCurrentUser={forCurrentUser}
                    isTeamMember={isTeamMember}
                    signingObject={signingObject}
                  />
                ) : (
                  <div className="cp-flex">
                    {forCurrentUser && (
                      <>
                        <div
                          className={a(
                            "custom-resizable-box-placeholder-content cp-caption cp-wt-semibold cp-ellipsis"
                          ).m("custom-resizable-box-placeholder-content-teamMember", isTeamMember)}
                          style={{
                            position: "absolute",
                            pointerEvents: "none",
                            opacity: textFieldValue ? 0 : 1,
                            transition: "opacity 0.2s",
                          }}
                        >
                          {showActiveSignerFieldExtras && !signingLocationsAreDraggable && "Click to add text"}
                        </div>
                        <CpTextarea
                          value={textFieldValue}
                          onChange={handleTextChange}
                          resize="none"
                          rows={Math.max(1, Math.floor(size.height / 16))}
                          className={a("custom-textarea cp-caption cp-wt-semibold cp-ellipsis").t(
                            "custom-textarea-active",
                            "custom-textarea-inactive",
                            forCurrentUser
                          )}
                          style={{ height: `${size.height}px` }}
                          onClick={handleClickShowToolbar}
                        />
                      </>
                    )}
                  </div>
                )}
                {!forCurrentUser && <div className="textarea-overlay" onClick={handleClickShowToolbar} />}
              </div>
            ) : (
              <div
                className={a("custom-resizable-box-placeholder-content cp-caption cp-wt-semibold cp-ellipsis").m(
                  "custom-resizable-box-placeholder-content-teamMember",
                  isTeamMember
                )}
                onClick={handleClick}
                onMouseDown={handleMouseDown}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    forCurrentUser && !isDateObject && !hasBeenSigned && openSignatureEntryModal();
                  }
                }}
              >
                {forCurrentUser &&
                  (context.loggedInUser.role === "TeamMember" || showActiveSignerFieldExtras) &&
                  isSignatureObject &&
                  !hasBeenSigned &&
                  "Click or enter to add signature"}
                {showActiveSignerFieldExtras &&
                  !isDateObject &&
                  !isSignatureObject &&
                  !hasBeenSigned &&
                  `Click to add ${signingObject.type === signingFieldTypes.INITIAL ? "initials" : "date"}`}
                {(hasBeenSigned || !!signerFieldProps?.signedLocation) && (
                  <div>
                    <CompletedField
                      {...signerFieldProps}
                      context={context}
                      forCurrentUser={forCurrentUser}
                      isTeamMember={isTeamMember}
                      signingObject={signingObject}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </ResizableBox>
      }
    </div>
  );
};

DraggableResizableField.displayName = "DraggableResizableField";

const css = k`
  .custom-resizable-box-header {
    display: flex;
    position: absolute;
    align-items: center;
    top: -22px;
    left: 0;
    width: fit-content;
    height: 22px;
    color: var(--cp-color-app-primary-dark-text);
    padding: 2px 4px;
    gap: 4px;
    border-radius: 8px 8px 0 0;
    z-index: 10;
    cursor: pointer;
  }

  .header-content {
    display: flex;
    align-items: center;
    min-width: 0;  
    flex: 1;
  }

  .header-text {
    font-size: 10px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
    margin-left: 4px;
  }

  .header-background-teamMember {
    background-color: var(--cp-color-pill-team-text);
  }

  .header-background-client {
    background-color: var(--cp-color-default-badge-bg);
  }

  .custom-resizable-box-border {
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--cp-color-default-badge-bg);
  }

  .custom-resizable-box-border-teamMember {
    border: 1px solid var(--cp-color-pill-team-text);
  }

  .custom-resizable-box-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--cp-color-pill-bg);
    opacity: 0.5;
    pointer-events: none;
    z-index: 1;
  }

  .custom-resizable-box-background-teamMember {
    background-color: var(--cp-color-pill-team-bg);
  }

  .custom-textarea {
    position: relative;
    display: flex;
    flex: 1;
    width: 100%; 
    min-height: 16px !important;
    padding: 0;
    border: none;
    background-color: transparent;
    color: var(--cp-color-app-primary-text);
    outline: none;
    box-shadow: none !important;
    line-height: 1.2;
    box-sizing: border-box;
    overflow: hidden;
    opacity: 1;
    z-index: 4;
    pointer-events: auto;
  }

  .custom-textarea-active {
    cursor: move;
  }

  .custom-textarea-active:focus {
    cursor: text;
  }

  .custom-textarea-inactive {
    cursor: move !important;
    user-select: none !important;
    pointer-events: none !important;
  }

  .textarea-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    cursor: move;
  }

  .custom-resizable-box-placeholder-content {
    width: 100%;
    height: 100%;
    position: relative;
    color: var(--cp-color-default-badge-bg);
    z-index: 3;
    display: flex;
    flex-direction: column; 
    justify-content: flex-end;
  }

  .custom-resizable-box-placeholder-content-teamMember {
    color: var(--cp-color-pill-team-text) !important;
  }
`;
