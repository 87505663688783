import { track as cpTrack } from "cp-analytics";

export const track = (event, data) => {
  return cpTrack("practice_management", "esign", event, data);
};

export const handleAction = (action, eventName) => {
  action();
  track(eventName);
};
