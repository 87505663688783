import React, { useRef, useEffect, useMemo, useContext, useState } from "react";
import { useCss, a, k } from "kremling";
import { CpContextDropdown, CpButton, CpIcon, CpDropdown, CpTooltip } from "canopy-styleguide!sofe";
import { typeIconMatch, signingFieldTypes, signatureElements } from "../../constants";
import { convertDateToString, userMatchesSigningLocation } from "../../signing-modal.helper";
import { SigningContext } from "../../signing-context";
import { DraggableResizableField } from "../draggable-resizable-field.component";
import { SignerName } from "./signer-name.component";

// TODO move into own component
const FieldTypeButton = ({ fieldType, currentType, changeFieldType }) => (
  <button
    className={`cp-select-list__item ${currentType === fieldType.type ? "cp-select-list__item--selected" : ""}`}
    onClick={() => changeFieldType(fieldType.type)}
  >
    <CpIcon className="cp-select-list__icon-left" name={fieldType.cpIcon} />
    <span>{fieldType.label}</span>
    {currentType === fieldType.type && (
      <CpIcon name="checkmark-small" style={{ marginLeft: "auto" }} fill="var(--cp-color-app-success-text)" />
    )}
  </button>
);

const textFields = {
  date: "Date",
  initial: "Initial",
  signature: "Recipient Signature",
  text: "Text",
  you: "You",
  yourInitial: "Your Initial",
  yourSignature: "Your Signature",
};

export function SignerFieldToolbar({
  allUsers,
  clientCollaborators,
  context,
  deleteSigningField,
  documentRef,
  duplicateSigningField,
  isActive,
  isDragging, // from DragWrapper
  markFieldAsSigned,
  onToolbarClose,
  onToolbarOpen,
  openSignatureEntryModal,
  resizeHandleRef, // from DragWrapper
  setSigner,
  showSignerDropdown,
  signingLocationsAreDraggable,
  signingObject,
  updateSigningObject,
  updateSigningObjectFieldType,
}) {
  const dropdownRef = useRef();
  const fieldRef = useRef();
  const toolbarRef = useRef();
  const scope = useCss(css);
  const buttonsContainerRef = useRef();
  const fieldIcon = typeIconMatch[signingObject.type];

  const signDate = signingObject.completed_at;
  const forCurrentUser = userMatchesSigningLocation(signingObject, context.loggedInUser);
  const client = allUsers.find((user) => userMatchesSigningLocation(signingObject, user));
  //TODO: do we need the clientCollaborators[signingObject.signatory_user_id] check?
  const clientName = client?.name || clientCollaborators[signingObject.signatory_user_id];
  const isSignatureObject = signingObject.type === signingFieldTypes.SIGNATURE;
  const isInitialObject = signingObject.type === signingFieldTypes.INITIAL;
  const isDateObject = signingObject.type === signingFieldTypes.DATE;
  const draggableRef = useRef();
  const isTextField = signingObject.type === signingFieldTypes.TEXT;
  const hasBeenSigned = signingObject.hasBeenSigned || signingObject.signed;
  const practitionerSignature = forCurrentUser && signingObject.isTeamMember;
  const displayValue = signingObject.value && signDate && (isDateObject || hasBeenSigned || practitionerSignature);
  const scrollRef = useRef();
  const { scrollTo, setScrollTo, signerTypesContext, activeSignerFieldId, setActiveSignerFieldId } =
    useContext(SigningContext);

  const [showFieldHeader, setShowFieldHeader] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState(signingObject?.value || "");
  // used to determine the active field on the signer view (client portal or secure link)
  const signerViewActiveField =
    !signingLocationsAreDraggable && activeSignerFieldId === signingObject.esigning_location_id;

  // Position toolbar above the resized height of the field with a small gap
  const toolbarOffset = signingObject.height + 4;

  const openToolbar = () => {
    if (!dropdownRef.current) return;
    setShowFieldHeader(false);
    // Initial position
    dropdownRef.current.open({
      top: -toolbarOffset,
      left: 0,
    });
  };

  const checkToolbarPosition = (fieldRect) => {
    if (!fieldRect) return;

    requestAnimationFrame(() => {
      const dropdownContent = toolbarRef.current;
      const rightSideButtonsWidth = buttonsContainerRef.current?.offsetWidth || 0;
      if (!dropdownContent || !rightSideButtonsWidth) return;
      const leftPanelWidth = 372;
      const safetyMargin = 16;
      // First check if we would overflow off the right side of the screen
      const wouldOverflowRight = fieldRect.left + rightSideButtonsWidth > window.innerWidth - leftPanelWidth;

      // Calculate max name width based on whether we need to shift
      let maxNameWidth;
      if (wouldOverflowRight) {
        // If shifting, use space from left panel to field position
        maxNameWidth = fieldRect.left - leftPanelWidth - safetyMargin;
      } else {
        // If not shifting, use space from field to right edge of screen
        maxNameWidth = window.innerWidth - fieldRect.right - rightSideButtonsWidth - safetyMargin + 86;
      }

      // Ensure minimum width of 100px and set the CSS variable
      maxNameWidth = Math.max(100, maxNameWidth);
      dropdownContent.style.setProperty("--max-name-width-toolbar", `${maxNameWidth}px`);

      // Get the new toolbar width after name is shortened (if needed)
      const toolbarWidth = dropdownContent.getBoundingClientRect().width;

      let leftOffset = 0;
      if (wouldOverflowRight) {
        // Align right edges of field and toolbar
        leftOffset = fieldRect.width - toolbarWidth;
      }

      // Reposition the dropdown
      dropdownRef.current.open({
        top: -toolbarOffset,
        left: leftOffset,
      });
    });
  };

  const handleShowToolbar = (e) => {
    if (!signingLocationsAreDraggable) return;
    onToolbarOpen();
    openToolbar();
    if (e?.target) {
      const fieldElement = e.target.closest("[data-field-container]");
      if (fieldElement) {
        checkToolbarPosition(fieldElement.getBoundingClientRect());
      }
    }
  };

  useEffect(() => {
    // close the toolbar when dragging a field
    if (isDragging && dropdownRef.current) {
      dropdownRef.current.close();
    }
  }, [isDragging]);

  useEffect(() => {
    // If toolbar is open and height changes, reposition it
    if (dropdownRef.current?.isOpen) {
      // if field is being resized, check position and adjust if needed
      const fieldElement = fieldRef.current?.closest("[data-field-container]");
      if (fieldElement) {
        checkToolbarPosition(fieldElement.getBoundingClientRect());
      }
    }
  }, [signingObject.height]);

  useEffect(() => {
    // Close dropdown when field becomes inactive
    if (!isActive && dropdownRef.current) {
      dropdownRef.current.close();
    }
  }, [isActive]);

  const deleteField = () => {
    deleteSigningField(signingObject.id);
  };

  const changeFieldType = (newType) => {
    updateSigningObjectFieldType(signingObject.id, newType);
  };

  useEffect(() => {
    if (showSignerDropdown?.id === signingObject.id) {
      draggableRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [showSignerDropdown]);

  const renderText = useMemo(() => {
    if (signingObject?.signer_type_name && !signingObject?.signatory_user_id) return signingObject.signer_type_name;
    if (displayValue) {
      return isDateObject ? convertDateToString(signDate) : signingObject.value;
    }
    if (forCurrentUser && signingLocationsAreDraggable) return textFields.you;
    if (forCurrentUser && !signingLocationsAreDraggable && isSignatureObject) return textFields.yourSignature;
    if (forCurrentUser && !signingLocationsAreDraggable && isInitialObject) return textFields.yourInitial;

    if (!forCurrentUser && clientName) return clientName;
    if (signingObject.signer_type?.name) return signingObject.signer_type.name;
    if (isSignatureObject) return textFields.signature;
    if (isInitialObject) return textFields.initial;
    if (isTextField) return textFields.text;
    return textFields.date;
  }, [
    signingObject.value,
    signingObject.signer_type_id,
    signingObject.signatory_user_id,
    signDate,
    clientName,
    hasBeenSigned,
  ]);

  useEffect(() => {
    if (scrollTo?.id === signingObject?.id) {
      scrollRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
      setScrollTo(null);
      setActiveSignerFieldId(signingObject.esigning_location_id);
    }
  }, [scrollTo]);

  const signerTypeForObject =
    signingObject?.signer_type_id && signerTypesContext?.find((type) => type?.id === signingObject?.signer_type_id);

  const signerFieldProps = {
    allowResize:
      signingLocationsAreDraggable ||
      (signerViewActiveField && signingObject.type === signingFieldTypes.SIGNATURE && signingObject.hasBeenSigned),
    dragHandle: signingLocationsAreDraggable,
    icon: !displayValue && typeIconMatch[signingObject?.type],
    teamMember: forCurrentUser || signerTypeForObject?.user_role === "TeamMember" || signingObject?.isTeamMember,
    text: renderText,
    onRemove: signingLocationsAreDraggable && deleteField,
    font: (displayValue || hasBeenSigned) && !isDateObject && !isTextField && signingObject?.font,
    type: signingObject?.type,
    staticLocation: signingObject?.esigning_location_id && signingObject.signed && !signingLocationsAreDraggable,
    signedLocation: (displayValue || hasBeenSigned) && signingObject?.value && signingObject?.completed_at,
  };

  return (
    <div ref={scrollRef}>
      <DraggableResizableField
        activeSignerFieldId={activeSignerFieldId}
        context={context}
        documentRef={documentRef}
        dropdownRef={dropdownRef}
        handleShowToolbar={handleShowToolbar}
        hasBeenSigned={hasBeenSigned}
        isDateObject={isDateObject}
        isSignatureObject={isSignatureObject}
        markFieldAsSigned={markFieldAsSigned}
        openSignatureEntryModal={openSignatureEntryModal}
        fieldRef={fieldRef}
        resizeHandleRef={resizeHandleRef}
        setActiveSignerFieldId={setActiveSignerFieldId}
        setShowFieldHeader={setShowFieldHeader}
        setTextFieldValue={setTextFieldValue}
        showFieldHeader={showFieldHeader}
        signerFieldProps={signerFieldProps}
        signingLocationsAreDraggable={signingLocationsAreDraggable}
        signingObject={signingObject}
        textFieldValue={textFieldValue}
        updateSigningObject={updateSigningObject}
      />
      <CpContextDropdown
        ref={dropdownRef}
        // TODO styleguide needs to be updated to allow auto width
        contentWidth="auto"
        position="top-start"
        allowContentClicks={true}
        isOpen={isActive}
        onClose={() => {
          setShowFieldHeader(true);
          onToolbarClose();
        }}
        renderContent={({ close }) => (
          <div {...scope} className="toolbar-styling" ref={toolbarRef}>
            <SignerName
              isDragging={isDragging}
              setSigner={setSigner}
              signerFieldProps={signerFieldProps}
              signingLocationsAreDraggable={signingLocationsAreDraggable}
              signingObject={signingObject}
              textFieldValue={textFieldValue}
            />
            <div className="cp-flex-center" ref={buttonsContainerRef}>
              <div className="toolbar-divider" />
              <CpDropdown
                renderTrigger={({ toggle }) => (
                  <CpTooltip text="Field type">
                    <CpButton
                      icon={fieldIcon}
                      aria-label="Change Field"
                      className="cp-mh-4"
                      btnType="icon-dark"
                      onClick={(e) => {
                        toggle(e);
                      }}
                    >
                      Options
                    </CpButton>
                  </CpTooltip>
                )}
                renderContent={() => (
                  <div className="cp-select-list">
                    {signatureElements.map((fieldType) => (
                      <FieldTypeButton
                        key={fieldType.type}
                        changeFieldType={changeFieldType}
                        currentType={signerFieldProps.type}
                        fieldType={fieldType}
                      />
                    ))}
                  </div>
                )}
              />
              <CpTooltip text="Duplicate">
                <CpButton
                  icon="crud-duplicate"
                  aria-label="Duplicate"
                  onClick={() => duplicateSigningField(signingObject)}
                  btnType="icon-dark"
                  className="flex-shrink-0 cp-mh-4"
                />
              </CpTooltip>
              <CpTooltip text="Delete">
                <CpButton
                  icon="crud-trash-large"
                  aria-label="Delete"
                  onClick={() => deleteField()}
                  btnType="icon-dark"
                  className="flex-shrink-0 cp-mh-4"
                />
              </CpTooltip>
            </div>
          </div>
        )}
      />
    </div>
  );
}

const css = k`
  .toolbar-styling {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    padding: 8px 8px 8px 0;
    width: fit-content;
    height: 40px;
    background: var(--cp-color-toast-bg);
    border-radius: 5px;
    white-space: nowrap;
  }

  .toolbar-divider {
    width: 1px;
    height: 20px;
    background-color: var(--cp-color-nav-active-border);
    margin: 0 16px;
  }
`;
