exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".letter-draggable-menu-item-styles__draggableElementsRow--2B8-6 {\n  flex: 0 1 auto;\n  width: 320px;\n  border-top: solid 1px #5f686d;\n  margin: 20px 10px 15px;\n  padding-top: 20px;\n}\n\n.letter-draggable-menu-item-styles__draggableElementsContainer--2VC-R {\n  display: flex;\n  display: -webkit-flex;\n  flex-flow: row wrap;\n  justify-content: center;\n  align-content: flex-start;\n  align-items: center;\n}\n\n.letter-draggable-menu-item-styles__draggableElement--16osz {\n  flex: 0 1 auto;\n  display: flex;\n  justify-content: flex-start;\n  align-content: center;\n  align-items: center;\n  margin: 8px;\n  height: 56px;\n  background-color: white;\n  border-radius: 5px;\n  -webkit-user-select: all;\n  user-select: none;\n  width: calc(50% - 18px);\n  padding: 8px;\n}\n\n.letter-draggable-menu-item-styles__dragHandleLargeGreen--3dHwU {\n  border: 2px dotted var(--cps-color-green);\n  border-top-style: none;\n  border-bottom-style: none;\n  height: 20px;\n  width: 6px;\n}\n\n.letter-draggable-menu-item-styles__dragHandleLargeBlue--3Pdvu {\n  border: 2px dotted var(--cps-color-info);\n  border-top-style: none;\n  border-bottom-style: none;\n  height: 20px;\n  width: 6px;\n}\n\n.letter-draggable-menu-item-styles__iconSpace--1AEVp {\n  margin: auto 8px;\n}\n", ""]);
// Exports
exports.locals = {
	"draggableElementsRow": "letter-draggable-menu-item-styles__draggableElementsRow--2B8-6",
	"draggableElementsContainer": "letter-draggable-menu-item-styles__draggableElementsContainer--2VC-R",
	"draggableElement": "letter-draggable-menu-item-styles__draggableElement--16osz",
	"dragHandleLargeGreen": "letter-draggable-menu-item-styles__dragHandleLargeGreen--3dHwU",
	"dragHandleLargeBlue": "letter-draggable-menu-item-styles__dragHandleLargeBlue--3Pdvu",
	"iconSpace": "letter-draggable-menu-item-styles__iconSpace--1AEVp"
};