import React from "react";
import { CpIcon } from "canopy-styleguide!sofe";
import { useCss, a, k } from "kremling";

//TODO use styleguide CpPill styles?
export const SignerPill = function SignerPill({
  signerPillRef,
  dragHandle,
  icon,
  className,
  disabled,
  teamMember,
  onRemove,
  text,
  onClick,
  dragSource,
  signedLocation,
  font,
  type,
  staticLocation,
}) {
  const scope = useCss(css);

  const signedLocationStyle = signedLocation ? { fontFamily: font, fontSize: type === "date" ? "16pt" : "20pt" } : {};

  return (
    <div
      {...scope}
      ref={signerPillRef}
      onClick={onClick}
      className={a("cp-pill")
        .a(className)
        .a("cp-pill-signing")
        .m("cp-pr-8", !onRemove)
        .m("cp-pill-green", teamMember)
        .m("cp-pill--disabled", disabled)
        .m("dragSource", dragSource)
        .m("cp-pill-static-location", staticLocation)}
    >
      {dragHandle && (
        <CpIcon
          style={{ marginLeft: "-.8rem" }}
          name="misc-drag-handle"
          fill={`${teamMember ? "var(--cp-color-pill-team-text)" : "var(--cps-color-pill-text)"}`}
        />
      )}
      {icon && <CpIcon name={icon} className="cp-mr-8" />}
      <span className="cp-ellipsis cp-pr-8" style={signedLocationStyle}>
        {text}
      </span>
      {onRemove && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }}
          type="button"
          disabled={disabled}
        >
          <CpIcon
            name="close-small"
            fill={`${teamMember ? "var(--cp-color-pill-team-text)" : "var(--cps-color-pill-text)"}`}
          />
        </button>
      )}
    </div>
  );
};

const css = k`
  .dragSource {
    width: auto !important;
    justify-content: flex-start !important;
  }
  .cp-pill-signing {
    height: 32px !important;
    max-width: 400px;
  }
  .cp-pill-static-location {
    background-color: transparent !important;
    border: 1px solid var(--cp-color-app-border);
    color: var(--cp-color-app-primary-text);
    cursor: default;
    pointer-events: none;
  }
`;
