import React from "react";
import { createPortal } from "react-dom";
import { partial } from "lodash";

import DocumentView from "../page-view-components/document-view.component.js";
import { PreviewDocument } from "../page-view-components/preview-document.component.js";

import SignatureEntryModal from "../signature-entry/signature-entry-modal.component.js";
import styles from "./esign-modal.styles.css";
import Disposable from "react-disposable-decorator";
import { CpLoader } from "canopy-styleguide!sofe";
import KbaAuth from "../kba/kba-auth.component";
import { fromSigningObservable } from "./esign-container.component";

let markAsSigned = () => {};

@Disposable
export class EsignInline extends React.Component {
  state = {
    displayKba: false,
    displaySignatureEntry: false,
  };

  renderLoading = () => {
    return (
      <div className={`cps-card cps-depth-2 ${styles.loadingWrapper} ${styles.document}`}>
        <div className={`${styles.loader}`}>
          <CpLoader />
        </div>
      </div>
    );
  };

  onCloseKbaModal = () => {
    this.setState({ displayKba: false });
    fromSigningObservable.next({ action: "CANCEL" });
  };

  onVerifiedSuccess = (success) => {
    if (success) {
      this.setState({ displayKba: false });
      this.props.shouldShowSignatureEntryModal(true);
    }
    this.props.verifyKba(success);
  };

  onSignatureClick = (markFieldAsSigned) => {
    markAsSigned = markFieldAsSigned;
    if (!this.props.isKbaVerified) {
      this.setState({ displayKba: true });
      fromSigningObservable.next({ action: "START" });
    } else {
      this.props.shouldShowSignatureEntryModal(true);
    }
  };

  onSetSignature = (state) => {
    this.props.setLoggedInUserSignature(state);
    markAsSigned();
  };

  render() {
    const {
      esignDocument,
      hasVerified,
      isClient,
      signer,
      clientId,
      signingSVGs,
      loggedInUserSignature,
      context,
      signingLocations,
      clientCollaborators,
      setSigningLocations,
      shouldShowSignatureEntryModal,
      openClientModal,
      setDocumentSizes,
      onSelectSigner,
      onRemoveSignature,
      showSignatureEntryModal,
      signatureEntryModalType,
      consented,
      updateConsent,
      setDocumentSize,
      signedSVGs,
      taxPrepManualSign,
      hideEsignRequest,
    } = this.props;

    const { displayKba } = this.state;

    return (
      <div className={``}>
        {displayKba &&
          !hasVerified &&
          !esignDocument.completed &&
          createPortal(
            <div className={`${styles.modalContent} cps-modal`}>
              <KbaAuth
                closeModal={this.onCloseKbaModal}
                signer={signer}
                kbaTimeLimit={esignDocument.kbaTimeLimit}
                attemptsRemaining={esignDocument.kbaAttemptsRemaining}
                verifyKba={this.onVerifiedSuccess}
                clientId={clientId}
                document={esignDocument}
                taxPrepManualSign={taxPrepManualSign}
                hideEsignRequest={hideEsignRequest}
              />
            </div>,
            document.body
          )}
        {showSignatureEntryModal && (
          <SignatureEntryModal
            shouldShowSignatureEntryModal={shouldShowSignatureEntryModal}
            setSignature={this.onSetSignature}
            context={context}
            signatureData={loggedInUserSignature}
            signingType={signatureEntryModalType}
            hasConsented={consented}
            updateConsent={updateConsent}
            documentId={esignDocument.id}
          />
        )}
        <div className={``}>
          <div id="docScroll" className={`${styles.documentPane}`}>
            {signingSVGs
              ? signingSVGs.map((signingSVG, page) => (
                  <DocumentView
                    key={page}
                    page={page}
                    signingSVG={signingSVG}
                    loggedInUserSignature={loggedInUserSignature}
                    shouldShowSignatureEntryModal={shouldShowSignatureEntryModal}
                    context={context}
                    signingLocations={signingLocations}
                    setSigningLocations={setSigningLocations}
                    clientCollaborators={clientCollaborators}
                    signingLocationsAreDraggable={false}
                    isClient={isClient}
                    openClientModal={openClientModal}
                    setDocumentSize={partial(setDocumentSizes, page)}
                    onSelectSigner={onSelectSigner}
                    onRemoveSignature={onRemoveSignature}
                    displayShield={true}
                    modalMode={false}
                    onSignatureClick={this.onSignatureClick}
                  />
                ))
              : !signedSVGs
              ? this.renderLoading()
              : null}
            {signedSVGs
              ? signedSVGs.map(() => <PreviewDocument setDocumentSize={setDocumentSize} />)
              : !signingSVGs
              ? this.renderLoading()
              : null}
          </div>
        </div>
      </div>
    );
  }
}
