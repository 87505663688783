import React from "react";
import PropTypes from "prop-types";
import { getDownloadUrl } from "../../signing.resource";
import { handleError } from "src/handle-error.helper";
import Disposable from "react-disposable-decorator";
import { CpButton } from "canopy-styleguide!sofe";

import styles from "../../signing-modal.styles.css";

@Disposable
export default class LetterHeaderBar extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    documentId: PropTypes.string,
    signingLocationsAreDraggable: PropTypes.bool.isRequired,
    signingRequestIsValid: PropTypes.bool.isRequired,
    completeDocumentAsClient: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    closeCancel: PropTypes.func.isRequired,
    clientRequestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    signingId: PropTypes.string,
    documentCompleted: PropTypes.bool,
    documentType: PropTypes.string,
    phoneOnly: PropTypes.bool,
  };

  state = {
    downloadUrl: `/api/letters/${this.props.documentId}/esign_docs/${
      this.props.signingId
    }/signing_requests/pdf?file_name=${encodeURI(this.props.title)}.pdf`,
    nextLocation: 0,
  };

  goTo = () => {
    const nextLocation = this.props.goToSigningLocations(this.state.nextLocation);
    this.setState({ nextLocation });
  };

  componentDidMount() {
    if (this.props.documentType === "file" && this.props.documentCompleted) {
      this.props.cancelWhenUnmounted(
        getDownloadUrl(this.props.documentId).subscribe((resp) => {
          this.setState({ downloadUrl: resp.signed_url });
        }, handleError)
      );
    }
  }

  render() {
    const {
      title,
      signingLocationsAreDraggable,
      clientRequestId,
      documentCompleted,
      signingRequestIsValid,
      completeDocumentAsClient,
      closeModal,
      closeCancel,
    } = this.props;
    const { downloadUrl } = this.state;
    const { phoneOnly } = this.props;

    return (
      <div
        className={`${styles.darkToolbar}`}
        style={{ ...(phoneOnly && { height: 60, width: "100%", position: "fixed", top: 0, left: 0 }) }}
      >
        <div className={`${styles.toolbarContainer}`} style={{ ...(phoneOnly && { flexDirection: "column" }) }}>
          <div className={`${styles.toolbarElement} cps-body`}>{title || "Signing document"}</div>
          <div className={`${styles.toolbarElement}`} style={{ justifyContent: "center" }}>
            {clientRequestId && documentCompleted && (
              <div className="cps-btn-icon">
                <a className="cps-link cps-margin-right-8" href={`${downloadUrl}`} download>
                  <span className="cps-icon cps-icon-download cps-white" />
                  <span className="cps-screenreader">Download {this.props.title} to your computer</span>
                </a>
              </div>
            )}
          </div>
          {!signingLocationsAreDraggable ? (
            <div className={`${styles.toolbarElement} cps-margin-right-24`} style={{ justifyContent: "flex-end" }}>
              <CpButton
                disabled={!this.props.signingPagesLoaded || signingRequestIsValid}
                btnType="secondary"
                icon="communication-signature-pen"
                className="cp-mr-16"
                onClick={this.goToNextLocation}
              >
                {signingRequestIsValid ? "All locations Signed" : "Go to next signature"}
              </CpButton>
              <CpButton btnType="secondary" className="cp-mr-16" onClick={closeModal}>
                Cancel
              </CpButton>
              <CpButton
                btnType="primary"
                className="cp-mr-16"
                disabled={!signingRequestIsValid}
                onClick={completeDocumentAsClient}
              >
                Send
              </CpButton>
            </div>
          ) : (
            <div className={`${styles.toolbarElement} cps-margin-right-24`} style={{ justifyContent: "flex-end" }}>
              <div className="cps-btn-icon">
                <a className="cps-link cps-margin-right-8" onClick={closeCancel}>
                  <span className="cps-icon cps-icon-close cps-white" />
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
