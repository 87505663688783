import React, { useContext, useState, useEffect } from "react";
import { SigningContext } from "../signing-context";
import { DragWrapper } from "./drag-field-wrapper.component";
import { SignerFieldToolbar } from "./signer-toolbar/signer-field-toolbar.component";
import NewDraggableSigningField from "./new-draggable-field.component";
import { userMatchesSigningLocation } from "../signing-modal.helper";
import { featureEnabled } from "feature-toggles!sofe";
import { ResizableFieldDimensions } from "../constants";
import { getDefaultFieldWidth } from "src/signing-resource.helper";
export const SigningObjects = ({
  documentRef,
  allSigningLocations,
  signingLocations,
  // page,
  loggedInUserSignature,
  signingLocationsAreDraggable,
  context,
  openSignatureEntryModal,
  isClient,
  clientCollaborators,
  displayShield,
  // modalMode,
  deleteSigningField,
  showSignerDropdown,
  preAuthSigner,
  // onSignatureClick,
  isTemplateMode,
  getFilledSignature,
  shouldShowSelectSignerModal,
  setSigner,
  setSigningLocations,
  duplicateSigningField,
  updateSigningObjectFieldType,
  markFieldAsSigned,
}) => {
  const { signersContext } = useContext(SigningContext);
  const [activeToolbarId, setActiveToolbarId] = useState(null);

  useEffect(() => {
    if (signingLocations.length > 0) {
      // if new field dragged on to page, set active toolbar to new field
      const newestField = signingLocations[signingLocations.length - 1];
      if (newestField) {
        setActiveToolbarId(newestField.id);
      }
    }
  }, [signingLocations.length]);

  const updateSigningObject = (updatedObject) => {
    const defaultWidth = getDefaultFieldWidth(updatedObject?.type);

    const newSigningLocations = allSigningLocations.map((obj) =>
      obj.id === updatedObject.id
        ? {
            ...obj,
            ...updatedObject,
            ...(featureEnabled("toggle_files_esign_improvements") && {
              width: updatedObject.width || defaultWidth,
              height: updatedObject.height || ResizableFieldDimensions.DEFAULT_HEIGHT,
            }),
          }
        : obj
    );

    setSigningLocations(newSigningLocations);
  };

  return signingLocations
    .filter((signingObject) =>
      isClient || preAuthSigner
        ? (signingObject.value && signingObject.completed_at) ||
          userMatchesSigningLocation(signingObject, context.loggedInUser)
        : true
    )
    .map((signingObject) => {
      const objectId = signingObject.id || signingObject.esigning_location_id;
      const key = `${objectId}${signingObject?.signatory_user_id}`;
      const innerRef = React.createRef();
      signingObject.ref = innerRef;
      const type = signingObject.type;

      const defaultWidth = getDefaultFieldWidth(type);
      const newSigningObject = {
        id: key,
        ...signingObject,
        ...getFilledSignature(loggedInUserSignature, signingObject),
        cpIcon:
          type === "signature"
            ? "communication-signature"
            : type === "initials"
            ? "communication-initials"
            : type === "date"
            ? "misc-calendar"
            : "misc-text-input",
        ...(featureEnabled("toggle_files_esign_improvements") && {
          width: signingObject.width || defaultWidth,
          height: signingObject.height || ResizableFieldDimensions.DEFAULT_HEIGHT,
        }),
      };
      return (
        <React.Fragment key={signingObject?.signatory_user_id ? key : objectId}>
          <DragWrapper
            canDrag={signingLocationsAreDraggable}
            signingObject={newSigningObject}
            clientCollaborators={clientCollaborators}
            allUsers={signersContext}
          >
            {featureEnabled("toggle_files_esign_improvements") ? (
              <SignerFieldToolbar
                allUsers={signersContext}
                clientCollaborators={clientCollaborators}
                context={context}
                deleteSigningField={deleteSigningField}
                documentRef={documentRef}
                duplicateSigningField={duplicateSigningField}
                isActive={activeToolbarId === signingObject.id}
                markFieldAsSigned={markFieldAsSigned}
                onToolbarClose={() => setActiveToolbarId(null)}
                onToolbarOpen={() => setActiveToolbarId(signingObject.id)}
                openSignatureEntryModal={() => {
                  openSignatureEntryModal(signingObject, objectId);
                  setActiveToolbarId(null);
                }}
                setSigner={setSigner}
                showSignerDropdown={showSignerDropdown}
                signingLocationsAreDraggable={signingLocationsAreDraggable}
                signingObject={newSigningObject}
                updateSigningObject={updateSigningObject}
                updateSigningObjectFieldType={updateSigningObjectFieldType}
              />
            ) : (
              <NewDraggableSigningField
                allUsers={signersContext}
                clientCollaborators={clientCollaborators}
                context={context}
                deleteSigningField={deleteSigningField}
                openSignatureEntryModal={() => openSignatureEntryModal(signingObject, objectId)}
                setSigner={setSigner}
                showSignerDropdown={showSignerDropdown}
                signingLocationsAreDraggable={signingLocationsAreDraggable}
                signingObject={newSigningObject}
              />
            )}
          </DragWrapper>
        </React.Fragment>
      );
    });
};
