import React from "react";
import PropTypes from "prop-types";
import styles from "../../signing-modal.styles.css";

export default class LetterEsignRequestSuccessModal extends React.Component {
  static propTypes = {
    clientId: PropTypes.string.isRequired,
    esignTaskId: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
    taskId: PropTypes.string,
  };
  render() {
    let taskUrl = `/#/tasks/clients/${this.props.clientId}?esignTaskCreated=${this.props.esignTaskId}`;

    // if there is a parent task then route to created subtask
    if (this.props.taskId) {
      taskUrl = `/#/task/${this.props.taskId}/client/${this.props.clientId}?subtask=${this.props.esignTaskId}`;
    }

    return (
      <div className={`cps-modal`}>
        <div className="cps-modal__screen"></div>
        <div className="cps-modal__dialog cps-card cps-card__height-2 cps-text-center cps-padding-24">
          <img src="https://cdn.canopytax.com/images/paper-plane.svg" alt="" />
          <h5 className={`cps-subheader cps-wt-bold ${styles.esignSuccessHeading}`}>
            Your client request has been sent
          </h5>
          <p className="cps-body cps-gray1 cps-margin-top-8">
            We've created a task to allow you to manage and view status for this&nbsp;request
          </p>
          <div className="cps-text-center cps-margin-top-24">
            {/* TODO: Use the correct queryParameter in url (see RED-631) */}
            <a
              href={taskUrl}
              onClick={() => this.props.closeModal("sent")}
              className="cps-btn +primary cps-margin-right-8"
            >
              View created task
            </a>
            <button type="button" className="cps-link cps-margin-left-8" onClick={() => this.props.closeModal("sent")}>
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
}
