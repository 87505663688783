import { featureEnabled } from "feature-toggles!sofe";

export const dragTypes = {
  DRAG_FIELD: "dragField",
  SIGNING_FIELD: "signingField",
};

export const signingFieldTypes = {
  SIGNATURE: "signature",
  INITIAL: "initials",
  DATE: "date",
  TEXT: "text",
};

export const typeIconMatch = {
  signature: "communication-signature",
  initials: "communication-initials",
  date: "misc-calendar",
  text: "misc-text-input",
};

export const signatureElements = [
  {
    type: signingFieldTypes.TEXT,
    text: "Text field",
    assignment: null,
    icon: "cps-icon-calendar",
    cpIcon: "misc-text-input",
    border: "var(--cp-color-app-primary)",
    color: "var(--cp-color-pill-bg)",
    label: "Text field",
  },
  {
    type: signingFieldTypes.SIGNATURE,
    text: "Signature",
    assignment: null,
    icon: "cps-icon-signature",
    cpIcon: "communication-signature",
    border: "var(--cp-color-app-primary)",
    color: "var(--cp-color-pill-bg)",
    ...(featureEnabled("toggle_files_esign_improvements")
      ? {}
      : {
          dragImage: {
            green: "https://cdn.canopytax.com/static/signing-ui/drag-sign-images/drag_signature_green_sm.svg",
            blue: "https://cdn.canopytax.com/static/signing-ui/drag-sign-images/drag_signature_blue_sm.svg",
          },
        }),
    label: "Signature",
  },
  {
    type: signingFieldTypes.INITIAL,
    text: "Initial",
    assignment: null,
    icon: "cps-icon-initial",
    cpIcon: "communication-initials",
    border: "var(--cp-color-app-primary)",
    color: "var(--cp-color-pill-bg)",
    ...(featureEnabled("toggle_files_esign_improvements")
      ? {}
      : {
          dragImage: {
            green: "https://cdn.canopytax.com/static/signing-ui/drag-sign-images/drag_signature_green_sm.svg",
            blue: "https://cdn.canopytax.com/static/signing-ui/drag-sign-images/drag_signature_blue_sm.svg",
          },
        }),
    label: "Initials",
  },
  {
    type: signingFieldTypes.DATE,
    text: "Date",
    assignment: null,
    icon: "cps-icon-calendar",
    cpIcon: "misc-calendar",
    border: "var(--cp-color-app-primary)",
    color: "var(--cp-color-pill-bg)",
    ...(featureEnabled("toggle_files_esign_improvements")
      ? {}
      : {
          dragImage: {
            green: "https://cdn.canopytax.com/static/signing-ui/drag-sign-images/drag_signature_green_sm.svg",
            blue: "https://cdn.canopytax.com/static/signing-ui/drag-sign-images/drag_signature_blue_sm.svg",
          },
        }),
    label: "Date",
  },
];

export const tabs = {
  SIGNATURE: "My signature",
  RECIPIENTS: "Recipients",
  REQUEST: "Send request",
};

export const featureToggles = ["esign_pdf"];

export const clientTypes = {
  currentlySelected: "Currently Selected",
  client: "Clients",
  other: "Other Clients",
  teamMember: "Team",
};

export const templateClientTypes = {
  currentlySelected: { key: "Current", name: "Currently Selected", icon: "person-people" },
  other: { key: "Other", name: "Other Clients", icon: "person-people" },
  teamMember: { key: "TeamMember", name: "Team", icon: "person-people" },
};

export const isNewCrmContactType = {
  client: { key: "Client", name: "Contacts", icon: "person" },
};

export const isOldCrmClientType = {
  client: { key: "Client", name: "Clients", icon: "person" },
};

export const templateSignerTypes = {
  preset: { name: "Preset signers" },
  custom: { name: "Custom signers" },
};

export const templateTypes = {
  preset: { id: "preset", name: "Preset", icon: "organize-template" },
  team: { id: "team", name: "Team", icon: "person-people" },
  private: { id: "private", name: "Private", icon: "person" },
};

export const userRoles = { client: "Client", teamMember: "TeamMember" };

export const signatureFonts = ["Courgette", "Allura", "Shadows Into Light Two", "Mr Dafoe"];

// threshold for determining if fields are at the "same" vertical position when sorting for next/prev location button
export const verticalThreshold = 5;
export const defaultSizeResizableField = { width: 160, height: 32 };

export const ResizableFieldDimensions = {
  DEFAULT_HEIGHT: 32,
  WIDE_WIDTH: 160, // for text and signature fields
  NARROW_WIDTH: 80, // for date and initial fields
};
