import React from "react";
import styles from "./common-signing-field.styles.css";
import { signingFieldTypes } from "../constants.js";
import { convertDateToString, userMatchesSigningLocation } from "../signing-modal.helper.js";

export const CompletedField = (props) => {
  const { signingObject, context } = props;
  const signDate = signingObject.completed_at;
  const forCurrentUser = userMatchesSigningLocation(signingObject, context.loggedInUser);
  const isDateObject = signingObject.type === signingFieldTypes.DATE;
  const isSignatureObject = signingObject.type === signingFieldTypes.SIGNATURE;
  const isInitialsObject = signingObject.type === signingFieldTypes.INITIAL;
  const hasBeenSigned = signingObject.hasBeenSigned || signingObject.signed;

  // const completed = signingObject.value && signDate && (!forCurrentUser || isDateObject || hasBeenSigned);

  return (
    <div
      className={`${styles.filledSigningObjectField} ${styles.modalMode}  ${isDateObject ? styles.dateField : ""}`}
      onClick={!isDateObject ? (forCurrentUser && !hasBeenSigned ? props.onClick : null) : null}
      style={{
        fontFamily: (isSignatureObject || isInitialsObject) && signingObject.font,
        cursor: !isDateObject && forCurrentUser && !hasBeenSigned ? "pointer" : "default",
      }}
    >
      {/* Display filled signature */}
      {isDateObject ? convertDateToString(signDate) : signingObject.value}
    </div>
  );
};

CompletedField.displayName = "CompletedField";
