import React from "react";

export const SigningContext = React.createContext({
  signersContext: [],
  setSignersContext: () => {},
  signerTypesContext: [],
  setSignerTypesContext: () => {},
  scrollTo: {},
  setScrollTo: () => {},
  selectedSignerIds: [],
  setSelectedSignerIds: () => {},
  getSelectedSigners: () => {},
  selectedSigner: null,
  setSelectedSigner: () => {},
});

export const EsignContext = React.createContext({
  esignContext: [],
  setEsignContext: () => {},
});
