import React, { useRef, Suspense, lazy } from "react";
import styles from "./description.styles.css";
import { CpLoader } from "canopy-styleguide!sofe";

const CpRichTextEditor = lazy(() =>
  import("rich-text-ui!sofe")
    .then((module) => module.getRichTextEditor())
    .then((rte) => rte)
);

export default function DescriptionRTE({ description, descriptionError, updateDescriptionRTE }) {
  const editorRef = useRef();
  const updateEditor = () => {
    const html = editorRef.current.getHTML();
    updateDescriptionRTE(html);
  };

  return (
    <Suspense fallback={<CpLoader dotSize={12} />}>
      <CpRichTextEditor
        id="esign-description"
        className={`${styles.rteEditor}`}
        initialHTML={description}
        editorRef={editorRef}
        placeholder="Add a description"
        onInput={updateEditor}
      />
      {descriptionError && (
        <div>
          <span className="cp-color-app-warning-text">* </span>
          {descriptionError}
        </div>
      )}
    </Suspense>
  );
}
